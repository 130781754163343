import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IBalanceAsset, IBalances } from '../../types/axios/Balances';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import TableCheckboxButton from '../miscellaneous/TableCheckbox/TableCheckboxButton';
import { useAuth } from '../../hooks/useAuth';
import BalanceRow from './BalanceRow';
import BalancesChart from './BalancesChart';

const REFETCH_AFTER = 60 * 1000;

const Balances: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [assetsToShow, setAssetsToShow] = useState<string[]>([]);
  const [{ data, error }, fetch] = useAxios<IBalances>({
    url: '/balances/getAll',
    method: 'GET',
    headers: { authorization: token },
  });

  const [balanceAssetsToShow] = useAxios<string>(
    {
      url: '/balances/getShowBalanceAssets',
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, updateAssetsToShow] = useAxios<string>(
    {
      url: '/balances/setShowBalanceAssets',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (balanceAssetsToShow.data) {
      setAssetsToShow(balanceAssetsToShow.data.split(','));
    }
  }, [balanceAssetsToShow.data]);

  useEffect(() => {
    if (assetsToShow.length) {
      updateAssetsToShow({ data: { value: assetsToShow.join(',') } });
    }
  }, [assetsToShow, updateAssetsToShow]);

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  const total: IBalanceAsset = {};
  if (data) {
    data.balances.forEach(({ assets }) => {
      Object.keys(assets).forEach(assetName => {
        total[assetName] = total[assetName] || 0;
        total[assetName] += assets[assetName];
      });
    });
  }

  return (
    <MainCardContainer>
      <div>
        {data === undefined || balanceAssetsToShow.data === undefined ? (
          <CentredLoader />
        ) : error || balanceAssetsToShow.error ? (
          <ErrorBox />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2>Balances</H2>
              <div>
                <TableCheckboxButton
                  btnText={'Visible assets'}
                  items={data.assets.map(asset => ({ title: asset, value: asset }))}
                  selectRowName="Assets"
                  defaultSelect={
                    balanceAssetsToShow.data.length ? balanceAssetsToShow.data.split(',') : []
                  }
                  onChangeValue={(assets: string[]) => setAssetsToShow(assets)}
                />
              </div>
            </div>
            <Table>
              <tbody>
                <Tr>
                  <Th>Exchange name</Th>
                  {assetsToShow.map(asset => (
                    <Th key={asset}>{asset}</Th>
                  ))}
                </Tr>
                {data.balances.map(balance => (
                  <BalanceRow key={balance.exchange} data={balance} assetsToShow={assetsToShow} />
                ))}
                <BalanceRow
                  key="total"
                  data={{ exchange: 'Total', assets: total }}
                  assetsToShow={assetsToShow}
                />
              </tbody>
            </Table>
            <BalancesChart assets={data.assets} />
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default Balances;
