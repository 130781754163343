import * as React from 'react';
import { FC } from 'react';
import { StatusBox } from './status-box';
import { clrError } from '../../styled/colors';

const ErrorBox: FC = () => (
  <StatusBox style={{ color: clrError }} text="Something went wrong.." />
);

export default ErrorBox;
