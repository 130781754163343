import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { H2 } from '../../styled/miscellaneous/hx';
import { IArbGraphPoint } from '../../types/axios/Arbitrage';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { dateMinusMin, dateToInput } from '../../utils/DateInputUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import { useAuth } from '../../hooks/useAuth';
import ArbOpportunitiesChart from './ArbOpportunitiesChart';
import {
  DAY_MS,
  HOUR_MS,
  THIRTY_MIN_MS,
  TEN_MIN_MS,
  FIVE_MIN_MS,
  moreDecimalsTickers,
} from '../../consts';

const DEFAULT_BUY_FROM = 'bitstamp';
const DEFAULT_SELL_AT = 'binance';
const DEFAULT_TICKER = 'BTCEUR';
const DEFAULT_CHART_DURATION_MS = 600000;

const rowStyle = { maxWidth: 120, width: '100%', paddingRight: 10 };

const buttonStyle = { marginRight: 10, marginTop: 'auto' };

const ArbOpportunitiesVisual: FC = () => {
  const [buyFromEx, setBuyFromEx] = useState(DEFAULT_BUY_FROM);
  const [sellAtEx, setSellAtEx] = useState(DEFAULT_SELL_AT);
  const [ticker, setTicker] = useState(DEFAULT_TICKER);
  const [from, setFrom] = useState(dateToInput(dateMinusMin(10)));
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }] = useAxios<{ tickers: string[]; exchanges: string[] }>({
    url: `/arbitrage/getArbVars`,
    method: 'GET',
    headers: { authorization: token },
  });

  const switchExchanges = () => {
    const buyFrom = buyFromEx;
    const sellAt = sellAtEx;
    setBuyFromEx(sellAt);
    setSellAtEx(buyFrom);
  };
  const addTime = (addMs: number) => {
    const newDate = new Date(new Date(from).getTime() + addMs);
    setFrom(dateToInput(newDate));
  };

  const [graphData, fetchGraph] = useAxios<IArbGraphPoint[]>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    } else if (error) {
      console.log(error);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (ticker && buyFromEx && sellAtEx && from) {
      try {
        const fromMs = new Date(from).getTime();
        fetchGraph({
          url: `arbitrage/getArbGraph/${fromMs}/${fromMs +
            DEFAULT_CHART_DURATION_MS}/${ticker}/${buyFromEx}/${sellAtEx}`,
        });
      } catch (e) {
        if (isAxiosError(e, AUTH_ERROR)) {
          navigate(RouterPaths.LOG_OUT);
        } else {
          console.log(e);
        }
      }
    }
  }, [ticker, from, buyFromEx, sellAtEx, navigate, fetchGraph]);

  const amountDecimals = ticker && moreDecimalsTickers.includes(ticker) ? 6 : 2;

  return (
    <div style={{ marginTop: 15 }}>
      <H2>Chart</H2>
      {data && (
        <>
          <div style={{ display: 'flex' }}>
            <div style={rowStyle}>
              <SelectBlock
                label="Buy from"
                options={[
                  { title: '', value: '' },
                  ...data.exchanges.map(exchange => ({ title: exchange, value: exchange })),
                ]}
                value={buyFromEx}
                onChange={e => setBuyFromEx(e.target.value)}
              />
            </div>
            <div style={{ paddingRight: 10, display: 'flex' }}>
              <Button blue padding="8px" style={{ marginTop: 'auto' }} onClick={switchExchanges}>
                ⇆
              </Button>
            </div>
            <div style={rowStyle}>
              <SelectBlock
                label="Sell at"
                options={[
                  { title: '', value: '' },
                  ...data.exchanges.map(exchange => ({ title: exchange, value: exchange })),
                ]}
                value={sellAtEx}
                onChange={e => setSellAtEx(e.target.value)}
              />
            </div>
            <div style={rowStyle}>
              <SelectBlock
                label="Ticker"
                options={[
                  { title: '', value: '' },
                  ...data.tickers.map(ticker => ({ title: ticker, value: ticker })),
                ]}
                value={ticker}
                onChange={e => setTicker(e.target.value)}
              />
            </div>
            <div style={{ maxWidth: 300, paddingRight: 10 }}>
              <InputBlock
                label="From (+10 min)"
                value={from}
                type="datetime-local"
                onChange={e => {
                  setFrom(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', paddingTop: 10 }}>
            <Button red padding="8px" style={buttonStyle} onClick={() => addTime(-1 * DAY_MS)}>
              -1D
            </Button>
            <Button red padding="8px" style={buttonStyle} onClick={() => addTime(-1 * HOUR_MS)}>
              -1h
            </Button>
            <Button
              red
              padding="8px"
              style={buttonStyle}
              onClick={() => addTime(-1 * THIRTY_MIN_MS)}
            >
              -30min
            </Button>
            <Button red padding="8px" style={buttonStyle} onClick={() => addTime(-1 * TEN_MIN_MS)}>
              -10min
            </Button>
            <Button red padding="8px" style={buttonStyle} onClick={() => addTime(-1 * FIVE_MIN_MS)}>
              -5min
            </Button>
            <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(FIVE_MIN_MS)}>
              +5min
            </Button>
            <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(TEN_MIN_MS)}>
              +10min
            </Button>
            <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(THIRTY_MIN_MS)}>
              +30min
            </Button>
            <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(HOUR_MS)}>
              +1h
            </Button>
            <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(DAY_MS)}>
              +1D
            </Button>
          </div>
        </>
      )}
      {graphData.data && (
        <ArbOpportunitiesChart data={graphData.data} amountDecimals={amountDecimals} />
      )}
    </div>
  );
};

export default ArbOpportunitiesVisual;
