import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApplicationContext from './components/ApplicationContext';
import WindowWidthProvider from './components/miscellaneous/WindowDimensionProvider';
import AuthProvider from './hooks/useAuth';
import GlobalStateProvider from './hooks/useGlobalState';
import { GlobalStyle } from './styled/globals';
import { initAxios } from './utils/axiosUtils';

initAxios();

const App: React.FC = () => {
  return (
    <>
      <WindowWidthProvider>
        <AuthProvider>
          <GlobalStateProvider>
            <BrowserRouter>
              <ApplicationContext />
            </BrowserRouter>
          </GlobalStateProvider>
        </AuthProvider>
      </WindowWidthProvider>
      <GlobalStyle />
    </>
  );
};

export default App;
