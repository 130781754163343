import * as React from 'react';
import { clrError } from '../../styled/colors';
import { IInputProps, InputWrap, Label, Select } from '../../styled/form/input';
import { Img } from '../../styled/miscellaneous/img';

export interface IOption {
  title: string;
  value?: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface ISelectProps extends IInputProps {
  name?: string;
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
  formal?: boolean;
  label?: string;
  id?: string;
  error?: boolean;
  options: IOption[];
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  style?: React.CSSProperties;
}

export const SelectBlock = (props: ISelectProps) => {
  const labelComponent = (
    <Label noColor htmlFor={props.id}>
      {props.label}
      {props.required && <span style={{ color: clrError }}>{' *'}</span>}
    </Label>
  );
  return (
    <InputWrap style={props.style}>
      {props.label && labelComponent}

      <Select {...props} value={props.value} onChange={props.onChange} invalid={props.error}>
        {props.options.map((option, index) => (
          <option
            key={index}
            value={option.value}
            selected={option.selected}
            disabled={option.disabled}
          >
            {option.title}
          </option>
        ))}
      </Select>

      <Img icon="icons/arrowhead-down-dark" />
    </InputWrap>
  );
};

export default SelectBlock;
