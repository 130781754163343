import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { AllMTsResponse, IMarketTaker, MarketTaker } from '../../types/axios/MarketTaker';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';
import MarketTakerRow from './MarketTakerRow';

const REFETCH_AFTER = 60 * 1000;

const MarketTakerComponent: FC = () => {
  const [postError, setPostError] = useState('');
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<AllMTsResponse[]>(
    {
      url: '/market-taker/getAll',
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, createMT] = useAxios<MarketTaker>(
    {
      url: '/market-taker/create',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  const fetchMM = async () => {
    await fetch();
  };

  const onCreate = async () => {
    setPostError('');
    try {
      await createMT({
        data: {
          intervalMs: 60000,
          intervalMsRandom: 60000,
          mmAccount: '',
          mtAccount: '',
          name: 'New Market Taker',
        } as IMarketTaker,
      });
      await fetchMM();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError('Server error. Cannot create new MT.');
      }
    }
  };

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Market Taker</H2>
        {error ? (
          <ErrorBox />
        ) : !data ? (
          <CentredLoader />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Algorithm name</Th>
                  <Th>Enabled</Th>
                  <Th colSpan={2}>Actions</Th>
                </Tr>
                {data.map(data => (
                  <MarketTakerRow
                    key={data.id}
                    {...data}
                    refetch={fetchMM}
                    setError={setPostError}
                  />
                ))}
              </tbody>
            </Table>
            <Button
              blue
              style={{
                marginTop: 15,
                padding: 8,
                width: '100%',
              }}
              onClick={onCreate}
            >
              Add New
            </Button>
          </>
        )}
        {postError && <StatusBox style={{ color: clrError }} text={postError} />}
      </div>
    </MainCardContainer>
  );
};

export default MarketTakerComponent;
