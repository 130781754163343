import styled from 'styled-components';
import { clrBluishLight } from '../colors';

export const Table = styled.table`
  border-collapse: collapse;
`;

interface ITDProps {
  right?: boolean;
}

export const Td = styled.td<ITDProps>`
  text-align: left;
  padding: 8px;
  font-size: 14px;
  ${props =>
    props.right &&
    `
    text-align: right;
  `};
`;

export interface ITHProps {
  right?: boolean;
}

export const Th = styled.th<ITHProps>`
  text-align: left;
  padding: 8px;
  font-weight: 600;
  font-size: 14px;
  ${props =>
    props.right &&
    `
    text-align: right;
  `};
`;

export interface ITRProps {
  underline?: boolean;
}

export const Tr = styled.tr<ITRProps>`
  ${props =>
    props.underline
      ? `border-bottom: 1px solid #eef4f4;`
      : `  &:nth-child(even) {
    background-color: ${clrBluishLight};
  }`};
`;
