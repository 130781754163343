import { useGlobalState } from './useGlobalState';

const useGetAssetObj = (assetName?: string) => {
  const { assets } = useGlobalState();
  const assetObj = assets?.find(({ name }) => name === assetName);

  return assetObj;
};

export default useGetAssetObj;
