import * as React from 'react';
import { P } from '../../styled/miscellaneous/p';

interface IProps {
  text?: string;
  style?: React.CSSProperties;
  mt?: number;
}

export const StatusBox: React.FC<IProps> = ({ style, children, text, mt }) => (
  <div style={style || { maxWidth: 350, margin: '0 auto', width: '100%', textAlign: 'center' }}>
    {text && <P style={{ marginTop: mt ?? 20, fontSize: '17px' }}>{text}</P>}
    {children}
  </div>
);
