import useAxios from 'axios-hooks';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';

const ExternalIp: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }] = useAxios<string>({
    url: '/external-ip/',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>External IP</H2>
        {!data ? <CentredLoader /> : error ? <ErrorBox /> : <>Your external IP: {data}</>}
      </div>
    </MainCardContainer>
  );
};

export default ExternalIp;
