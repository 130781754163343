import useAxios from 'axios-hooks';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { MarketTakerTicker } from '../../types/axios/MarketTaker';
import { ITicker } from '../../types/axios/PlaceOrder';
import { AUTH_ERROR, ILLEGAL_OPERATION_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: MarketTakerTicker;
  tickers: ITicker[];
  refetch: () => void;
  setError: (value: React.SetStateAction<string>) => void;
}

const EMPTY_OPTION = { title: '', value: '' };
const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const MarketTakerEditTickersRow: FC<IProps> = ({ data, tickers, refetch, setError }) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [ticker, setTicker] = useState(data.ticker);
  const [minAmt, setMinAmt] = useState(data.minAmt.toString());
  const [maxAmt, setMaxAmt] = useState(data.maxAmt.toString());
  const [probability, setProbability] = useState(data.probability.toString());
  const [pricePrecision, setPricePrecision] = useState(data.pricePrecision.toString());
  const [qtyPrecision, setQtyPrecision] = useState(data.quantityPrecision.toString());

  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/market-taker-ticker/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, update] = useAxios(
    {
      url: '/market-taker-ticker/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    try {
      await deleteItem({
        data: { id: data.id },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setError('Server error. Unable to delete this Time.');
      }
    }
  };

  const onUpdate = async () => {
    try {
      await update({
        data: {
          id: data.id,
          ticker,
          minAmt: Number(minAmt),
          maxAmt: Number(maxAmt),
          mtId: data.mtId,
          probability: Number(probability),
          pricePrecision: Number(pricePrecision),
          quantityPrecision: Number(qtyPrecision),
        } as MarketTakerTicker,
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setError('Server error.');
      }
    }
  };

  return (
    <Tr>
      <Td>
        <SelectBlock
          style={{ width: 100 }}
          options={
            data
              ? [
                  EMPTY_OPTION,
                  ...tickers.map(({ name }) => ({
                    title: name,
                    value: name,
                  })),
                ]
              : [EMPTY_OPTION]
          }
          value={ticker}
          onChange={e => setTicker(e.target.value)}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 50, width: '100%' }}
          type="number"
          value={probability}
          onChange={e => {
            setProbability(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 70, width: '100%' }}
          type="number"
          value={minAmt}
          onChange={e => {
            setMinAmt(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 70, width: '100%' }}
          type="number"
          value={maxAmt}
          onChange={e => {
            setMaxAmt(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 40, width: '100%' }}
          type="number"
          value={pricePrecision}
          onChange={e => {
            setPricePrecision(Number(e.target.value).toFixed(0));
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 40, width: '100%' }}
          type="number"
          value={qtyPrecision}
          onChange={e => {
            setQtyPrecision(Number(e.target.value).toFixed(0));
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
      <Td>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export default MarketTakerEditTickersRow;
