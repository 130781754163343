import React, { FC } from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { clrBluishViolet, clrGrid, clrRed } from '../../styled/colors';
import { IArbGraphPoint } from '../../types/axios/Arbitrage';
import { zeroPadded } from '../../utils/formatUtils';

const TICK_COUNT = 10;

interface IProps {
  data: IArbGraphPoint[];
  amountDecimals: number;
}

const chartOffsetFromTopBottom = 0.2;

const ArbOpportunitiesChart: FC<IProps> = ({ data, amountDecimals }) => {
  if (data.length > 1) {
    const maxPercentValue = parseFloat(
      (
        Math.max(...data.map(item => Math.abs(item.arbPercent))) *
        (1 + chartOffsetFromTopBottom)
      ).toFixed(amountDecimals),
    );
    const maxAmountValue = parseFloat(
      (
        Math.max(...data.map(item => Math.abs(item.arbAmount))) *
        (1 + chartOffsetFromTopBottom)
      ).toFixed(amountDecimals),
    );

    return (
      <div style={{ marginTop: 10 }}>
        <LineChart
          width={800}
          height={500}
          data={data.map(({ timeMs, arbAmount, arbPercent }) => ({
            name: timeMs,
            arbAmount: Math.round(arbAmount * 100) / 100,
            arbPercent: Math.round(arbPercent * 100) / 100,
          }))}
          margin={{
            top: 10,
            right: 30,
            left: 10,
          }}
        >
          <CartesianGrid stroke={clrGrid} />
          <XAxis
            dataKey="name"
            type="number"
            scale="time"
            tickFormatter={(tick: number) => {
              const date = new Date(tick);
              return `${zeroPadded(date.getHours())}:${zeroPadded(date.getMinutes())}`;
            }}
            domain={['dataMin', 'dataMax']}
            ticks={getTicks(data)}
          />
          <YAxis
            yAxisId="left"
            label={{
              value: `%`,
              angle: -90,
              position: 'insideLeft',
              offset: 5,
            }}
            ticks={[-1 * maxPercentValue, 0, maxPercentValue]}
            domain={[-1 * maxPercentValue, maxPercentValue]}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{ value: 'AMOUNT', angle: -90, position: 'insideRight' }}
            ticks={[-1 * maxAmountValue, 0, maxAmountValue]}
            domain={[-1 * maxAmountValue, maxAmountValue]}
          />
          <Tooltip
            labelFormatter={tick => {
              const date = new Date(tick);
              return `${date.getFullYear()}-${zeroPadded(date.getMonth() + 1)}-${zeroPadded(
                date.getDate(),
              )} ${zeroPadded(date.getHours())}:${zeroPadded(date.getMinutes())}:${zeroPadded(
                date.getSeconds(),
              )}`;
            }}
          />
          <Legend verticalAlign="top" height={40} />

          <Line
            yAxisId="left"
            connectNulls
            type="monotone"
            dataKey="arbPercent"
            stroke={clrBluishViolet}
            strokeWidth={2}
          />
          <Line
            yAxisId="right"
            connectNulls
            type="monotone"
            dataKey="arbAmount"
            stroke={clrRed}
            strokeWidth={2}
          />
        </LineChart>
      </div>
    );
  }
  return <>No data </>;
};

export default ArbOpportunitiesChart;

const getTicks = (data: IArbGraphPoint[]) => {
  const startMs = data[0].timeMs;
  const endMs = data[data.length - 1].timeMs;
  const interval = (endMs - startMs) / TICK_COUNT;
  const ticks = [];
  for (let i = 0; i < TICK_COUNT; i++) {
    ticks.push(startMs + i * interval);
  }
  return ticks;
};
