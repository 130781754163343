import * as React from 'react';
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { FormRow } from '../../styled/containers/FormContainer';
import { clrError } from '../../styled/colors';
import { P } from '../../styled/miscellaneous/p';

interface IProps {
  rowStyle?: React.CSSProperties;
}

const ErrorRow: React.FC<IProps> = ({ children, rowStyle }) => (
  <FormRow style={rowStyle}>
    <P style={{ color: clrError }}>{children}</P>
  </FormRow>
);

export const ErrorRowMessage = (props: ErrorMessageProps & { rowStyle?: React.CSSProperties }) => (
  <ErrorMessage {...props} component={ErrorRow} />
);
