import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DAY_MS } from '../../consts';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IArbitrages } from '../../types/axios/Arbitrage';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { dateToInput } from '../../utils/DateInputUtils';
import { CheckboxBlock } from '../form/checkbox-block';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';
import usePagination from '../../hooks/usePagination';
import PaginationRow from '../miscellaneous/PaginationRow';
import ArbOpportunitiesRow from './ArbOpportunitiesRow';
import ArbOpportunitiesVisual from './ArbOpportunitiesVisual';

const rowStyle = { maxWidth: 120, width: '100%', paddingRight: 10 };
const DEFAULT_DROPDOWN = 'all';
const buttonStyle = { marginRight: 10, marginTop: 'auto' };

const ArbOpportunities: FC = () => {
  const [buyFromEx, setBuyFromEx] = useState(DEFAULT_DROPDOWN);
  const [sellAtEx, setSellAtEx] = useState(DEFAULT_DROPDOWN);
  const [ticker, setTicker] = useState(DEFAULT_DROPDOWN);
  const [to, setTo] = useState(dateToInput(new Date()));
  const [profitBox, setProfitBox] = useState(false);
  const [diffBox, setDiffBox] = useState(false);
  const [profit, setProfit] = useState('0');
  const [diff, setDiff] = useState('0');
  const [profitParam, setProfitParam] = useState('0');
  const [diffParam, setDiffParam] = useState('0');
  const { limit, offset, setItemCount, onClickFirstPage, ...pagProps } = usePagination();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [arbVars] = useAxios<{ tickers: string[]; exchanges: string[] }>({
    url: `/arbitrage/getArbVars`,
    method: 'GET',
    headers: { authorization: token },
  });

  const [{ data, error, loading }] = useAxios<IArbitrages>({
    url: `/arbitrage/getSome`,
    method: 'POST',
    headers: { authorization: token },
    data: {
      from: new Date(to).getTime() - DAY_MS,
      to: new Date(to).getTime(),
      limit,
      offset,
      buyOrderExchange: sellAtEx !== DEFAULT_DROPDOWN ? sellAtEx : undefined,
      sellOrderExchange: buyFromEx !== DEFAULT_DROPDOWN ? buyFromEx : undefined,
      ticker: ticker !== DEFAULT_DROPDOWN ? ticker : undefined,
      arbAmount: profitBox ? profitParam : undefined,
      arbPercent: diffBox ? diffParam : undefined,
    },
    timeout: 90000,
  });

  useEffect(() => {
    onClickFirstPage();
    // eslint-disable-next-line
  }, [buyFromEx, sellAtEx, ticker, to, profitBox, diffBox, profitParam, diffParam]);

  useEffect(() => {
    if (data?.count !== undefined) {
      setItemCount(data.count);
    }
  }, [data, setItemCount]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, arbVars.error, navigate]);

  const switchExchanges = () => {
    const buyFrom = buyFromEx;
    const sellAt = sellAtEx;
    setBuyFromEx(sellAt);
    setSellAtEx(buyFrom);
  };

  const addTime = (addMs: number) => {
    const newDate = new Date(new Date(to).getTime() + addMs);
    setTo(dateToInput(newDate));
  };

  const tickerObj = useGlobalState().tickers?.find(({ name }) => name === ticker);
  const setParams = (profit?: string, diff?: string, ticker?: string) => {
    if (profit) {
      setProfit(profit);
      setProfitParam(profit);
      setProfitBox(true);
    }
    if (diff) {
      setDiff(diff);
      setDiffParam(diff);
      setDiffBox(true);
    }
    if (ticker) {
      setTicker(ticker);
    }
  };

  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <H2>Arbitrage Opportunities (Last 1000)</H2>
            <div style={{ display: 'flex' }}>
              <div style={{ maxWidth: 300, paddingRight: 10 }}>
                <InputBlock
                  label="Date (last 24h)"
                  value={to}
                  type="datetime-local"
                  onChange={e => {
                    setTo(e.target.value);
                  }}
                />
              </div>
              <Button red padding="8px" style={buttonStyle} onClick={() => addTime(-1 * DAY_MS)}>
                -1D
              </Button>
              <Button blue padding="8px" style={buttonStyle} onClick={() => addTime(DAY_MS)}>
                +1D
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <A cursorPointer onClick={() => setParams('1', '0.3')}>{`profit >1; diff > 0.3%`}</A>
            <A cursorPointer onClick={() => setParams('1', '0.6')}>{`profit >1; diff > 0.6%`}</A>
            <A cursorPointer onClick={() => setParams('5', '0.2')}>{`profit >5; diff > 0.2%`}</A>
            <A
              cursorPointer
              onClick={() => setParams('0.0001', '0.2', 'ETHBTC')}
            >{`profit > 0.0001; diff > 0.2%; ticker = ETHBTC`}</A>
          </div>
        </div>
        {!arbVars.data || loading ? (
          <CentredLoader />
        ) : arbVars.error ? (
          <ErrorBox />
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <div style={rowStyle}>
                <SelectBlock
                  label="Buy from"
                  options={[
                    { title: DEFAULT_DROPDOWN, value: DEFAULT_DROPDOWN },
                    ...arbVars.data.exchanges.map(exchange => ({
                      title: exchange,
                      value: exchange,
                    })),
                  ]}
                  value={buyFromEx}
                  onChange={e => setBuyFromEx(e.target.value)}
                />
              </div>
              <div style={{ paddingRight: 10, display: 'flex' }}>
                <Button blue padding="8px" style={{ marginTop: 'auto' }} onClick={switchExchanges}>
                  ⇆
                </Button>
              </div>
              <div style={rowStyle}>
                <SelectBlock
                  label="Sell at"
                  options={[
                    { title: DEFAULT_DROPDOWN, value: DEFAULT_DROPDOWN },
                    ...arbVars.data.exchanges.map(exchange => ({
                      title: exchange,
                      value: exchange,
                    })),
                  ]}
                  value={sellAtEx}
                  onChange={e => setSellAtEx(e.target.value)}
                />
              </div>
              <div style={rowStyle}>
                <SelectBlock
                  label="Ticker"
                  options={[
                    { title: DEFAULT_DROPDOWN, value: DEFAULT_DROPDOWN },
                    ...arbVars.data.tickers.map(ticker => ({ title: ticker, value: ticker })),
                  ]}
                  value={ticker}
                  onChange={e => setTicker(e.target.value)}
                />
              </div>
              <div style={{ ...rowStyle, display: 'flex', maxWidth: 170 }}>
                <CheckboxBlock
                  style={{ marginTop: 'auto' }}
                  value={profitBox}
                  onChange={e => {
                    setProfitBox(e.target.checked);
                  }}
                >
                  {'Profit >'}
                </CheckboxBlock>
                <InputBlock
                  style={{ marginLeft: 10, marginTop: 'auto', width: 60 }}
                  label=""
                  type="number"
                  value={profit}
                  onChange={e => {
                    setProfit(e.target.value);
                  }}
                  onBlur={e => {
                    setProfitParam(e.target.value);
                  }}
                />
              </div>
              <div style={{ ...rowStyle, display: 'flex', maxWidth: 170 }}>
                <CheckboxBlock
                  style={{ marginTop: 'auto' }}
                  value={diffBox}
                  onChange={e => {
                    setDiffBox(e.target.checked);
                  }}
                >
                  {'Diff (%) >'}
                </CheckboxBlock>
                <InputBlock
                  style={{ marginLeft: 10, marginTop: 'auto', width: 60 }}
                  label=""
                  type="number"
                  value={diff}
                  onChange={e => {
                    setDiff(e.target.value);
                  }}
                  onBlur={e => {
                    setDiffParam(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!data || loading ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Buy from</Th>
                  <Th>Sell at</Th>
                  <Th>Ticker</Th>
                  <Th>Quantity</Th>
                  <Th>Profit</Th>
                  <Th>Diff (%)</Th>
                  <Th>Date</Th>
                </Tr>
                {data.items.map(item => (
                  <ArbOpportunitiesRow
                    key={`${item.buyOrderExchange}${item.sellOrderExchange}${item.ticker}${item.timestamp}`}
                    data={item}
                    amountDecimals={tickerObj?.baseDecimal}
                  />
                ))}
              </tbody>
            </Table>
            <PaginationRow {...pagProps} />
          </>
        )}
        <ArbOpportunitiesVisual />
      </div>
    </MainCardContainer>
  );
};

export default ArbOpportunities;
