import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { CheckboxBlock } from '../form/checkbox-block';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  getURI: string;
  updateURI: string;
  paramName: string;
  isBool?: boolean;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const ConfigRow: FC<IProps> = ({ getURI, updateURI, isBool, paramName }) => {
  const [value, setValue] = useState(isBool ? false : '');
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, loading, error }, fetchValue] = useAxios<string | boolean>({
    url: getURI,
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setValue(data);
    }
  }, [data]);

  const [updateObj, updateValue] = useAxios<boolean>(
    {
      url: updateURI,
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onUpdate = async () => {
    try {
      await updateValue({
        data: { value },
      });
      await fetchValue();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      }
    }
  };

  return (
    <Tr>
      {loading || updateObj.loading ? (
        <Td colSpan={3}>Loading..</Td>
      ) : error || updateObj.error ? (
        <Td colSpan={3} style={{ color: clrError }}>
          Something went wrong..
        </Td>
      ) : (
        <>
          <Td>{paramName}</Td>
          <Td>
            {isBool ? (
              <CheckboxBlock
                value={!!value}
                onChange={e => {
                  setValue(e.target.checked);
                }}
              />
            ) : (
              <InputBlock
                style={{ maxWidth: 300, width: '100%' }}
                value={value.toString()}
                onChange={e => {
                  setValue(e.target.value);
                }}
              />
            )}
          </Td>
          <Td>
            <Button style={buttonStyle} blue onClick={onUpdate}>
              Update
            </Button>
          </Td>
        </>
      )}
    </Tr>
  );
};

export default ConfigRow;
