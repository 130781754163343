import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Button } from '../../../styled/buttons/Button';
import { Img } from '../../../styled/miscellaneous/img';
import { elementContains } from '../OutsideClose';
import TableCheckbox, { ITableCheckboxProps } from './TableSelectTable/TableCheckbox';

const dropdownHeight = 300;

interface IProps extends ITableCheckboxProps {
  style?: React.CSSProperties;
  btnText: string;
}

const TableCheckboxButton: FC<IProps> = ({
  style,
  onChangeValue: propsOnChangeValue,
  btnText,
  ...tsProps
}) => {
  const divRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();
  const [showSelect, setShowSelect] = useState(false);

  const onChangeValue = (newValue: string[]) => {
    propsOnChangeValue && propsOnChangeValue(newValue);
  };

  useEffect(() => {
    const handleMouseClick = (event: MouseEvent) => {
      if (
        event.target &&
        divRef.current &&
        !elementContains(divRef.current, event.target as HTMLElement) &&
        event.target !== buttonRef.current
      ) {
        setShowSelect(false);
      }
    };

    document.addEventListener('mousedown', handleMouseClick, false);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, [showSelect, divRef, buttonRef]);

  return (
    <div style={{ position: 'relative' }}>
      <Button blue ref={buttonRef} onClick={() => setShowSelect(!showSelect)} style={style}>
        {btnText}
        <Img icon="icons/arrowhead-down-white" style={{ marginLeft: 10 }} />
      </Button>
      <div style={{ position: 'absolute', right: 0, zIndex: 100 }}>
        <div
          ref={divRef}
          style={{
            position: 'relative',
            height: dropdownHeight,
            right: 0,
            top: 0,
            display: showSelect ? 'flex' : 'none',
            flexDirection: 'column',
          }}
        >
          <TableCheckbox onChangeValue={onChangeValue} {...tsProps} isOpen={showSelect} />
        </div>
      </div>
    </div>
  );
};

export default TableCheckboxButton;
