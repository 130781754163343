import { FC } from 'react';
import { Td, Tr } from '../../styled/table/table';

interface IProps {
  data: [string, number];
}

const PriceDecimalsRow: FC<IProps> = ({ data }) => {
  return (
    <Tr>
      <Td>{data[0]}</Td>
      <Td>{data[1]}</Td>
    </Tr>
  );
};

export default PriceDecimalsRow;
