import useAxios from 'axios-hooks';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { IMinQty, IMinQtyUpdate } from '../../types/axios/MinQty';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: IMinQty;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => Promise<void>;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const MinQtyRow: FC<IProps> = ({ data, setPostError, fetch }) => {
  const [asset, setAsset] = useState(data.asset);
  const [quantity, setQuantity] = useState(data.quantity);

  const navigate = useNavigate();
  const { token } = useAuth();
  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/min-qty/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, updateItem] = useAxios<boolean>(
    {
      url: '/min-qty/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    setPostError(false);
    try {
      await deleteItem({
        data: { id: data.id },
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  const onUpdate = async () => {
    setPostError(false);
    try {
      await updateItem({
        data: {
          id: data.id,
          asset,
          quantity,
        } as IMinQtyUpdate,
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  return (
    <Tr>
      <Td>
        <InputBlock
          style={{ maxWidth: 100, width: '100%' }}
          value={asset}
          onChange={e => {
            setAsset(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 300, width: '100%' }}
          value={quantity}
          onChange={e => {
            setQuantity(e.target.value);
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
      <Td>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export default MinQtyRow;
