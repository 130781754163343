import * as React from 'react';
import { FC } from 'react';
import { Td, Tr } from '../../../../styled/table/table';

interface IProps {
  selected?: boolean;
  onClick?: (asset: string) => void;
  text: string;
  value?: string;
}

const TableSelectRow: FC<IProps> = ({ selected, onClick, text, value }) => {
  return (
    <Tr underline>
      <Td>
        <div
          style={{
            padding: '0 5px',
            cursor: 'pointer',
            fontWeight: selected ? 500 : undefined,
          }}
          onClick={() => onClick && onClick(value ?? text)}
        >
          {selected ? <strong>{text}</strong> : text}
        </div>
      </Td>
    </Tr>
  );
};

export default TableSelectRow;
