import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Td, Th, Tr } from '../../styled/table/table';
import { IMarketMakerUpdate, MarketMaker, SplitAmtTypes } from '../../types/axios/MarketMaker';
import { IPlaceOrderVar } from '../../types/axios/PlaceOrder';
import {
  AUTH_ERROR,
  BAD_ARGS_ERROR,
  ILLEGAL_OPERATION_ERROR,
  isAxiosError,
} from '../../utils/axiosUtils';
import { CheckboxBlock } from '../form/checkbox-block';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import CentredLoader from '../miscellaneous/CenteredLoader';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';

const CONV_STR = 'conv';
const EMPTY_OPTION = { title: '', value: '' };
const CONST_OPTION = { title: 'CONST', value: 'const' };
const ONE_BY_ONE_OPTION = { title: 'none (1:1)', value: '1:1' };

const MAX_DED_AMT_BIDS_DECIMAL = 2;
const MAX_DED_QTY_ASKS_DECIMAL = 8;
const MAX_PRC_FRM_SPREAD_DECIMAL = 2;
const MAX_SPREAD_DECIMAL = 2;
const MAX_SLIPPAGE_DECIMAL = 2;

const { EQUAL, RANDOM, RANDOM_50 } = SplitAmtTypes;

const MarketMakerEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [postError, setPostError] = useState('');

  const [{ data, error }] = useAxios<IPlaceOrderVar[]>({
    url: '/orders/getPlaceOrderVars',
    method: 'GET',
    headers: { authorization: token },
  });

  const [mmData] = useAxios<MarketMaker>(
    {
      url: `/market-maker/getOne/${id}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, updateMM] = useAxios<MarketMaker>(
    {
      url: '/market-maker/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (
      (error && isAxiosError(error, AUTH_ERROR)) ||
      (mmData.error && isAxiosError(mmData.error, AUTH_ERROR))
    ) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, mmData.error, navigate]);

  const [name, setName] = useState(mmData.data?.name || '');
  const [exchangeBase, setExchangeBase] = useState(mmData.data?.exchangeBase || '');
  const [exchangeBaseFallback, setExchangeBaseFallback] = useState(
    mmData.data?.exchangeBaseFallback || '',
  );
  const [baseAmount, setBaseAmount] = useState(mmData.data?.baseAmount.toString() || '');
  const [exchangeMM, setExchangeMM] = useState(mmData.data?.exchangeMM || '');
  const [ticker, setTicker] = useState(
    mmData.data?.baseAsset && mmData.data?.quoteAsset
      ? `${mmData.data.baseAsset}-${mmData.data.quoteAsset}`
      : '',
  );
  const [dedAmtBids, setDedAmtBids] = useState(mmData.data?.dedicatedAmountBids.toString() || '');
  const [dedQtyAsks, setDedQtyAsks] = useState(mmData.data?.dedicatedQuantityAsks.toString() || '');
  const [ordersOnSide, setOrdersOnSide] = useState(mmData.data?.ordersOnOneSide.toString() || '');
  const [priceStepsFromSpread, setPriceStepsFromSpread] = useState(
    mmData.data?.priceStepsFromSpread.toString() || '',
  );
  const [spread, setSpread] = useState(mmData.data?.spread.toString() || '');
  const [ordersDistributionType, setOrdersDistributionType] = useState(
    mmData.data?.ordersDistributionType || RANDOM,
  );
  const [minimalProfitMargin, setMinimalProfitMargin] = useState(
    mmData.data?.minimalProfitMargin.toString() || '0',
  );
  const [pricePrecision, setPricePrecision] = useState(
    mmData.data?.pricePrecision.toString() || '2',
  );
  const [pricePrecisionBase, setPricePrecisionBase] = useState(
    mmData.data?.pricePrecisionBase.toString() || '1',
  );
  const [quantityPrecision, setQuantityPrecision] = useState(
    mmData.data?.quantityPrecision.toString() || '8',
  );
  const [slippageAllowance, setSlippageAllowance] = useState(
    mmData.data?.slippageAllowance.toString() || '0.2',
  );
  const [updateOrdersInterval, setUpdateOrdersInterval] = useState(
    mmData.data?.updateOrdersInterval.toString() || '60',
  );
  const [ignoreBaseExchangeFees, setIgnoreBaseExchangeFees] = useState(
    mmData.data?.ignoreBaseExchangeFees || false,
  );
  const [ignoreBaseExchangeNotifs, setIgnoreBaseExchangeNotifs] = useState(
    mmData.data?.ignoreBaseExchangeNotifs || false,
  );
  const [orderValidTime, setOrderValidTime] = useState(
    mmData.data?.orderValidTime.toString() || '15',
  );
  const [altTicker, setAltTicker] = useState(
    mmData.data?.altBaseAsset && mmData.data?.altQuoteAsset
      ? `${mmData.data.altBaseAsset}_${mmData.data.altQuoteAsset}`
      : '',
  );
  const [convExchange, setConvExchange] = useState(mmData.data?.altConversionExchange || '');
  const [useAftermatch, setUseAftermatch] = useState(mmData.data?.useAftermatch ?? true);

  useEffect(() => {
    if (mmData.data) {
      setName(mmData.data.name);
      setExchangeBase(mmData.data.exchangeBase);
      setExchangeBaseFallback(mmData.data.exchangeBaseFallback);
      setBaseAmount(mmData.data.baseAmount.toString());
      setExchangeMM(mmData.data.exchangeMM);
      setDedAmtBids(mmData.data.dedicatedAmountBids.toString());
      setDedQtyAsks(mmData.data.dedicatedQuantityAsks.toString());
      setOrdersOnSide(mmData.data.ordersOnOneSide.toString());
      setPriceStepsFromSpread(mmData.data.priceStepsFromSpread.toString());
      setSpread(mmData.data.spread.toString());
      if (mmData.data.baseAsset && mmData.data.quoteAsset) {
        setTicker(
          `${mmData.data.baseAsset}_${mmData.data.quoteAsset}${
            mmData.data.altBaseAsset && mmData.data.altQuoteAsset ? `_${CONV_STR}` : ''
          }`,
        );
      }
      setOrdersDistributionType(mmData.data.ordersDistributionType);
      setMinimalProfitMargin(mmData.data.minimalProfitMargin.toString());
      setPricePrecision(mmData.data.pricePrecision.toString());
      setPricePrecisionBase(mmData.data.pricePrecisionBase.toString());
      setQuantityPrecision(mmData.data.quantityPrecision.toString());
      setSlippageAllowance(mmData.data.slippageAllowance.toString());
      setUpdateOrdersInterval(mmData.data.updateOrdersInterval.toString());
      setIgnoreBaseExchangeFees(mmData.data.ignoreBaseExchangeFees);
      setIgnoreBaseExchangeNotifs(mmData.data.ignoreBaseExchangeNotifs);
      setOrderValidTime(mmData.data.orderValidTime.toString());
      if (mmData.data.altBaseAsset && mmData.data.altQuoteAsset) {
        setAltTicker(`${mmData.data.altBaseAsset}_${mmData.data.altQuoteAsset}`);
      }
      setConvExchange(mmData.data.altConversionExchange);
      setUseAftermatch(mmData.data.useAftermatch);
    }
  }, [mmData.data]);

  useEffect(() => {
    const dedAmtBidsReplaced = dedAmtBids.replace(',', '.');
    const decimals = dedAmtBidsReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_DED_AMT_BIDS_DECIMAL) {
      setDedAmtBids(dedAmtBids.slice(0, MAX_DED_AMT_BIDS_DECIMAL - decimals.length));
    }
  }, [dedAmtBids]);

  useEffect(() => {
    const dedQtyAsksReplaced = dedQtyAsks.replace(',', '.');
    const decimals = dedQtyAsksReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_DED_QTY_ASKS_DECIMAL) {
      setDedQtyAsks(dedQtyAsks.slice(0, MAX_DED_QTY_ASKS_DECIMAL - decimals.length));
    }
  }, [dedQtyAsks]);

  useEffect(() => {
    const ordOneSideReplaced = ordersOnSide.replace(',', '.');
    if (ordOneSideReplaced.includes('.')) {
      setOrdersOnSide(ordersOnSide.slice(0, -1));
    }
  }, [ordersOnSide]);

  useEffect(() => {
    const prcFromSpreadReplaced = priceStepsFromSpread.replace(',', '.');
    const decimals = prcFromSpreadReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_PRC_FRM_SPREAD_DECIMAL) {
      setPriceStepsFromSpread(
        priceStepsFromSpread.slice(0, MAX_PRC_FRM_SPREAD_DECIMAL - decimals.length),
      );
    }
  }, [priceStepsFromSpread]);

  useEffect(() => {
    const spreadReplaced = spread.replace(',', '.');
    const decimals = spreadReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_SPREAD_DECIMAL) {
      setSpread(spread.slice(0, MAX_SPREAD_DECIMAL - decimals.length));
    }
  }, [spread]);

  useEffect(() => {
    const pricePrecisionReplaced = pricePrecision.replace(',', '.');
    if (pricePrecisionReplaced.includes('.')) {
      setPricePrecision(pricePrecision.slice(0, -1));
    }
  }, [pricePrecision]);

  useEffect(() => {
    const pricePrecisionBaseReplaced = pricePrecisionBase.replace(',', '.');
    if (pricePrecisionBaseReplaced.includes('.')) {
      setPricePrecisionBase(pricePrecisionBase.slice(0, -1));
    }
  }, [pricePrecisionBase]);

  useEffect(() => {
    const quantityPrecisionReplaced = quantityPrecision.replace(',', '.');
    if (quantityPrecisionReplaced.includes('.')) {
      setQuantityPrecision(quantityPrecision.slice(0, -1));
    }
  }, [quantityPrecision]);

  useEffect(() => {
    const slippageReplaced = slippageAllowance.replace(',', '.');
    const decimals = slippageReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_SLIPPAGE_DECIMAL) {
      setSlippageAllowance(slippageAllowance.slice(0, MAX_SLIPPAGE_DECIMAL - decimals.length));
    }
  }, [slippageAllowance]);

  useEffect(() => {
    const updateOrdersIntervalReplaced = updateOrdersInterval.replace(',', '.');
    if (updateOrdersIntervalReplaced.includes('.')) {
      setUpdateOrdersInterval(updateOrdersInterval.slice(0, -1));
    }
  }, [updateOrdersInterval]);

  useEffect(() => {
    const orderValidTimeReplaced = orderValidTime.replace(',', '.');
    if (orderValidTimeReplaced.includes('.')) {
      setOrderValidTime(orderValidTime.slice(0, -1));
    }
  }, [orderValidTime]);

  const onSubmit = async () => {
    setPostError('');
    const base = ticker.split('_')[0];
    const quote = ticker.split('_')[1];
    const needsConv = ticker.split('_')[2] === CONV_STR;
    const altBase = altTicker.split('_')[0];
    const altQuote = altTicker.split('_')[1];
    if (needsConv && (!altBase || !altQuote)) {
      setPostError('If you are using ticker with CONVERSION, you must specify base ticker.');
      return;
    }
    if (needsConv && !convExchange && !(base === altQuote && quote === altBase)) {
      setPostError(
        'If you are using ticker with CONVERSION, you must specify conversion exchange.',
      );
      return;
    }

    try {
      await updateMM({
        data: {
          id: Number(id),
          name,
          exchangeBase,
          exchangeBaseFallback,
          exchangeMM,
          baseAmount: Number(baseAmount),
          baseAsset: base || '',
          quoteAsset: quote || '',
          dedicatedAmountBids: Number(dedAmtBids),
          dedicatedQuantityAsks: Number(dedQtyAsks),
          ordersOnOneSide: Number(ordersOnSide),
          priceStepsFromSpread: Number(priceStepsFromSpread),
          spread: Number(spread),
          ordersDistributionType: ordersDistributionType || undefined,
          minimalProfitMargin: minimalProfitMargin ? Number(minimalProfitMargin) : undefined,
          pricePrecision: pricePrecision ? Number(pricePrecision) : undefined,
          pricePrecisionBase: pricePrecisionBase ? Number(pricePrecisionBase) : undefined,
          quantityPrecision: quantityPrecision ? Number(quantityPrecision) : undefined,
          slippageAllowance: slippageAllowance ? Number(slippageAllowance) : undefined,
          updateOrdersInterval: updateOrdersInterval ? Number(updateOrdersInterval) : undefined,
          ignoreBaseExchangeFees,
          ignoreBaseExchangeNotifs,
          orderValidTime: Number(orderValidTime),
          altBaseAsset: needsConv ? altBase : '',
          altQuoteAsset: needsConv ? altQuote : '',
          altConversionExchange: needsConv ? convExchange : '',
          useAftermatch,
        } as IMarketMakerUpdate,
      });
      navigate(RouterPaths.MARKET_MAKER);
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setPostError(
          'Cannot update this Market Maker while it is running. Please disable it first.',
        );
      } else if (isAxiosError(error, BAD_ARGS_ERROR)) {
        setPostError('Spread has to be 2+ times bigger than slippageAllowance.');
      } else {
        setPostError('Server error.Cannot update this MM.');
      }
    }
  };

  const baseExchangeOptions = data
    ? data.map(item => ({
        title: item.exchange,
        value: item.exchange,
        disabled: !item.wsEnabled,
      }))
    : [];

  const baseExTickers = data
    ? data.find(exchange => exchange.exchange === exchangeBase)?.tickers || []
    : [];
  const mmTickers = data
    ? data.find(exchange => exchange.exchange === exchangeMM)?.tickers || []
    : [];

  const tickerOptions = [
    EMPTY_OPTION,
    ...mmTickers.map(({ name, base, quote }) => {
      if (
        exchangeBase === CONST_OPTION.value ||
        !!baseExTickers.find(ticker => ticker.name === name)
      ) {
        return {
          title: name,
          value: `${base}_${quote}`,
        };
      }
      return {
        title: `${name} (with conv)`,
        value: `${base}_${quote}_${CONV_STR}`,
      };
    }),
  ];

  const base = ticker.split('_')[0];
  const quote = ticker.split('_')[1];
  const altTickerOptions = [
    EMPTY_OPTION,
    ...baseExTickers.map(({ name, base, quote }) => {
      return {
        title: name,
        value: `${base}_${quote}`,
      };
    }),
  ];

  const altBase = altTicker.split('_')[0];
  const altQuote = altTicker.split('_')[1];
  const showConvRows = ticker.split('_')[2] === CONV_STR;
  const showConvExchangeRow = base !== altQuote || quote !== altBase;

  const neededAssets =
    base === altBase
      ? [quote, altQuote]
      : quote === altQuote
      ? [base, altBase]
      : base === altQuote
      ? [quote, altBase]
      : quote === altBase
      ? [base, altQuote]
      : [];

  const convExchangeOptions = data
    ? [
        ...data
          .filter(
            ({ enabled, tickers, wsEnabled }) =>
              enabled &&
              wsEnabled &&
              !!tickers.find(
                ({ base, quote }) => neededAssets.includes(base) && neededAssets.includes(quote),
              ),
          )
          .map(({ tickers, exchange }) => {
            const ticker = tickers.find(
              ({ base, quote }) => neededAssets.includes(base) && neededAssets.includes(quote),
            );
            return {
              title: `${exchange} ${ticker ? `(${ticker.name})` : ''}`,
              value: exchange,
            };
          }),
      ]
    : [];

  return (
    <MainCardContainer>
      <div>
        <H2>Market Maker (edit)</H2>
        {!mmData.data ? (
          <CentredLoader />
        ) : (
          <Table>
            <tbody>
              <Tr>
                <Th>Param name</Th>
                <Th>Value</Th>
              </Tr>
              <Tr>
                <Td>Name</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Exchange Base</Td>
                <Td>
                  <SelectBlock
                    options={[EMPTY_OPTION, ...baseExchangeOptions, CONST_OPTION]}
                    value={exchangeBase}
                    onChange={e => setExchangeBase(e.target.value)}
                  />
                </Td>
              </Tr>
              {exchangeBase === CONST_OPTION.value && (
                <Tr>
                  <Td>Base Amount</Td>
                  <Td>
                    <InputBlock
                      style={{ maxWidth: 300, width: '100%' }}
                      value={baseAmount}
                      type="number"
                      onChange={e => {
                        setBaseAmount(e.target.value);
                      }}
                    />
                  </Td>
                </Tr>
              )}
              <Tr>
                <Td>Exchange Base Fallback</Td>
                <Td>
                  <SelectBlock
                    options={[EMPTY_OPTION, ...baseExchangeOptions]}
                    value={exchangeBaseFallback}
                    onChange={e => setExchangeBaseFallback(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Exchange MM</Td>
                <Td>
                  <SelectBlock
                    options={
                      data
                        ? [
                            EMPTY_OPTION,
                            ...data.map(item => ({
                              title: item.exchange,
                              value: item.exchange,
                              disabled: !item.enabled,
                            })),
                          ]
                        : [EMPTY_OPTION]
                    }
                    value={exchangeMM}
                    onChange={e => setExchangeMM(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Ticker</Td>
                <Td>
                  <SelectBlock
                    options={tickerOptions}
                    value={ticker}
                    onChange={e => setTicker(e.target.value)}
                  />
                </Td>
              </Tr>
              {showConvRows && (
                <>
                  <Tr>
                    <Td>Base ticker</Td>
                    <Td>
                      <SelectBlock
                        options={altTickerOptions}
                        value={altTicker}
                        onChange={e => setAltTicker(e.target.value)}
                      />
                    </Td>
                  </Tr>
                  {showConvExchangeRow && (
                    <Tr>
                      <Td>Conversion exchange</Td>
                      <Td>
                        <SelectBlock
                          options={[EMPTY_OPTION, ...convExchangeOptions, ONE_BY_ONE_OPTION]}
                          value={convExchange}
                          onChange={e => setConvExchange(e.target.value)}
                        />
                      </Td>
                    </Tr>
                  )}
                </>
              )}
              <Tr>
                <Td>Dedicated Amount Bids</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={dedAmtBids}
                    type={'number'}
                    onChange={e => {
                      setDedAmtBids(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Dedicated quantity asks</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={dedQtyAsks}
                    type={'number'}
                    onChange={e => {
                      setDedQtyAsks(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Orders on one side</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={ordersOnSide}
                    type={'number'}
                    onChange={e => {
                      console.log(e.target.value);

                      setOrdersOnSide(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Price steps from spread</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={priceStepsFromSpread}
                    type={'number'}
                    onChange={e => {
                      setPriceStepsFromSpread(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Spread</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={spread}
                    type={'number'}
                    onChange={e => {
                      setSpread(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Orders distribution type</Td>
                <Td>
                  <SelectBlock
                    options={[
                      { title: 'Random', value: RANDOM },
                      { title: 'Random+50%', value: RANDOM_50 },
                      { title: 'Equal sizes', value: EQUAL },
                    ]}
                    value={ordersDistributionType}
                    onChange={e =>
                      setOrdersDistributionType(
                        e.target.value === RANDOM
                          ? RANDOM
                          : e.target.value === RANDOM_50
                          ? RANDOM_50
                          : EQUAL,
                      )
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Minimal profit margin</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={minimalProfitMargin}
                    type={'number'}
                    onChange={e => {
                      setMinimalProfitMargin(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Price precision</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={pricePrecision}
                    type={'number'}
                    onChange={e => {
                      setPricePrecision(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Price presicion base</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={pricePrecisionBase}
                    type={'number'}
                    onChange={e => {
                      setPricePrecisionBase(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Quantity precision</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={quantityPrecision}
                    type={'number'}
                    onChange={e => {
                      setQuantityPrecision(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Slippage allowance</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={slippageAllowance}
                    type={'number'}
                    onChange={e => {
                      setSlippageAllowance(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Orders update interval (s)</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={updateOrdersInterval}
                    type={'number'}
                    onChange={e => {
                      setUpdateOrdersInterval(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Ignore base exchange fees</Td>
                <Td>
                  <CheckboxBlock
                    style={{ display: 'flex', flexDirection: 'column' }}
                    value={ignoreBaseExchangeFees}
                    onChange={e => {
                      setIgnoreBaseExchangeFees(e.target.checked);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Ignore base exchange notifs</Td>
                <Td>
                  <CheckboxBlock
                    style={{ display: 'flex', flexDirection: 'column' }}
                    value={ignoreBaseExchangeNotifs}
                    onChange={e => {
                      setIgnoreBaseExchangeNotifs(e.target.checked);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Orders valid time (s)</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={orderValidTime}
                    type={'number'}
                    onChange={e => {
                      setOrderValidTime(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Use aftermatch</Td>
                <Td>
                  <CheckboxBlock
                    style={{ display: 'flex', flexDirection: 'column' }}
                    value={useAftermatch}
                    onChange={e => {
                      setUseAftermatch(e.target.checked);
                    }}
                  />
                </Td>
              </Tr>
            </tbody>
          </Table>
        )}
        <Button
          blue
          style={{
            marginTop: 15,
            padding: 8,
            width: '100%',
          }}
          onClick={onSubmit}
        >
          Update
        </Button>
        {postError && <StatusBox style={{ color: clrError }} text={postError} />}
      </div>
    </MainCardContainer>
  );
};

export default MarketMakerEdit;
