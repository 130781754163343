import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { FormRow } from '../../styled/containers/FormContainer';
import { getNestedValueByString } from '../../utils/formikUtils';
import { ErrorRowMessage } from './ErrorRowMessage';
import { InputBlock } from './input-block';

interface IProps extends FieldProps {
  rowStyle?: React.CSSProperties;
  errorRowStyle?: React.CSSProperties;
  afterChange?(): void;
}

const FormInputRow = ({
  field,
  form: { touched, errors },
  rowStyle,
  errorRowStyle,
  afterChange,
  ...props
}: IProps) => (
  <>
    <FormRow style={rowStyle}>
      <InputBlock
        error={
          getNestedValueByString(touched, field.name) && getNestedValueByString(errors, field.name)
            ? true
            : false
        }
        {...field}
        {...props}
        onChange={e => {
          field.onChange(e);
          afterChange && afterChange();
        }}
      />
    </FormRow>
    <ErrorRowMessage name={field.name} rowStyle={errorRowStyle} />
  </>
);

export const FormInputRowField = (props: any) => <Field {...props} component={FormInputRow} />;
