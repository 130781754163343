import * as React from 'react';
import { FC } from 'react';
import { Table, Th, Tr } from '../../styled/table/table';
import { IArbOppAlterating } from '../../types/axios/Arbitrage';
import ArbOppsAlteratingRow from './ArbOppsAlteratingRow';

interface IProps {
  data: IArbOppAlterating[];
}

const ArbOppsAlteratingTable: FC<IProps> = ({ data }) => {
  return (
    <Table>
      <tbody>
        <Tr>
          <Th>Ticker</Th>
          <Th>Direction</Th>
          <Th>Avg pnl %</Th>
          <Th>Avg pnl amt</Th>
          <Th>Opp count</Th>
          <Th>Number of days</Th>
        </Tr>
        {data.sort(sortFn).map(arbOpp => (
          <ArbOppsAlteratingRow
            key={`${arbOpp.ticker}-${arbOpp.buyExchange}-${arbOpp.sellExchange}`}
            data={arbOpp}
          />
        ))}
      </tbody>
    </Table>
  );
};

const sortFn = (a: IArbOppAlterating, b: IArbOppAlterating) => {
  const result = b.numOfDays - a.numOfDays;
  return result === 0
    ? Math.min(b.oppCount, b.oppCountReverse) - Math.min(a.oppCount, a.oppCountReverse)
    : result;
};

export default ArbOppsAlteratingTable;
