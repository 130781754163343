import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { Ticker } from '../../types/axios/Tickers';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: Ticker;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const TickerRow: FC<IProps> = ({ data, setPostError }) => {
  const [baseDecimal, setBaseDecimal] = useState(data.baseDecimal.toString());
  const [quoteDecimal, setQuoteDecimal] = useState(data.quoteDecimal.toString());

  const navigate = useNavigate();
  const { token } = useAuth();

  const [, update] = useAxios<boolean>(
    {
      url: '/ticker/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onUpdate = async () => {
    setPostError(false);
    try {
      await update({
        data: {
          name: data.name,
          baseDecimal: Number(baseDecimal),
          quoteDecimal: Number(quoteDecimal),
        },
      });
      // await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  useEffect(() => {
    const baseDecimalReplaced = baseDecimal.replace(',', '.');
    if (baseDecimalReplaced.includes('.')) {
      setBaseDecimal(baseDecimal.slice(0, -1));
    }
  }, [baseDecimal]);

  useEffect(() => {
    const quoteDecimalReplaced = quoteDecimal.replace(',', '.');
    if (quoteDecimalReplaced.includes('.')) {
      setQuoteDecimal(quoteDecimal.slice(0, -1));
    }
  }, [quoteDecimal]);

  return (
    <Tr>
      <Td>{data.name}</Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 300, width: '100%' }}
          value={baseDecimal}
          type="number"
          onChange={e => {
            setBaseDecimal(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 300, width: '100%' }}
          value={quoteDecimal}
          type="number"
          onChange={e => {
            setQuoteDecimal(e.target.value);
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
    </Tr>
  );
};

export default TickerRow;
