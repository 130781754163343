import React, { FC } from 'react';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Td, Tr } from '../../styled/table/table';
import { IResult } from '../../types/axios/Results';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  data: IResult;
  customName?: string;
  bold?: boolean;
}

const ResultsRow: FC<IProps> = ({ data, customName, bold }) => {
  const decimal = useGlobalState().tickers?.find(({ name }) => name === data.ticker)?.quoteDecimal;
  if (!decimal) {
    return <></>;
  }
  return (
    <Tr style={{ fontWeight: bold ? 600 : undefined }}>
      <Td style={{ width: 100 }}>{customName ?? data.ticker ?? '-'}</Td>
      <Td style={{ width: 200 }} right>
        {numFormat(data.pnl, decimal)}
      </Td>
    </Tr>
  );
};

export default ResultsRow;
