import React, { FC } from 'react';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Td, Tr } from '../../styled/table/table';
import { IArbOppAlterating } from '../../types/axios/Arbitrage';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  data: IArbOppAlterating;
}

const ArbOppsAlteratingRow: FC<IProps> = ({ data }) => {
  const {
    avgPerc,
    avgPercReverse,
    avgPnl,
    avgPnlReverse,
    buyExchange,
    numOfDays,
    oppCount,
    oppCountReverse,
    sellExchange,
    ticker,
  } = data;
  const { tickers } = useGlobalState();
  const tickerObj = tickers?.find(({ name }) => name === ticker);
  const pnlDecimals = tickerObj?.quoteDecimal || 2;
  const quoteAsset = tickerObj?.quote;
  return (
    <Tr>
      <Td>{ticker}</Td>
      <Td>
        <div>
          {sellExchange} (B) {`->`} {buyExchange} (S)
        </div>
        <div>
          {buyExchange} (B) {`->`} {sellExchange} (S)
        </div>
      </Td>
      <Td>
        <div>{avgPerc.toFixed(2)}%</div>
        <div>{avgPercReverse.toFixed(2)}%</div>
      </Td>
      <Td>
        <div>
          {numFormat(avgPnl, pnlDecimals)} {quoteAsset}
        </div>
        <div>
          {numFormat(avgPnlReverse, pnlDecimals)} {quoteAsset}
        </div>
      </Td>
      <Td>
        <div>{oppCount}</div>
        <div>{oppCountReverse}</div>
      </Td>
      <Td>{numOfDays}</Td>
    </Tr>
  );
};

export default ArbOppsAlteratingRow;
