export const clrWhite = '#ffffff';
export const clrBck = '#e5e5e5';

export const clrBlue = '#1b283e';
export const clrBlueLight = '#449DCC';
export const clrBluishLight = `#e8f9ff`;

export const clrGreenish = '#e4f9f8';
export const clrGreenishAccent = '#8ecdc2';
export const clrGreenishMuted = '#dee7e7';
export const clrGreenishLight = '#eef4f4';
export const clrGreenishHover = '#7db8ae';
export const clrGreenishDark = '#7dbcb1';
export const clrGreenishAttention = '#82c7d2';
export const clrGreenishDisabled = '#c7c7c7';

export const clrDataGreen = '#c3ece2';
export const clrDataBlue = '#e4f9f8';
export const clrDataRose = '#fbdbe2';

export const clrBluish = '#80c0d2';
export const clrBluishAccent = '#a7ced4';
export const clrBluishHover = '#60b0c6';
export const clrBluishDark = '#7abbcd';
export const clrBluishAttention = '#70a9b9';
export const clrBluishViolet = '#6370F9';

export const clrRedishAccent = '#e17a7a';
export const clrRedishHover = '#d44040';
export const clrRed = '#EE2626';

export const clrWhiteMist = '#fbfdfd';

export const clrText = '#5e6f81';
export const clrTextDark = '#233549';
export const clrTextMuted = '#859a9a';
export const clrTextBlur = '#a6b6b6';
export const clrTextGreenish = '#7cb9b1';

export const clrTextOrange = '#cc9900';

export const clrDivider = '#eef4f4';
export const clrDividerMuted = '#d9e6e6';

export const clrSuccess = '#01cc00';
export const clrWarning = '#ffd857';
export const clrError = '#ec464a';
export const clrInfo = '#33ccff';

export const clrBackgroundDisabled = '#cccccc';

export const clrNotifYellowish = '#fefbdd';

export const clrDarkOrangeText = '#735c0e';

export const clrGrid = '#e1ecf2';

export const clrBlack = '#000000';
