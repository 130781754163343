import * as React from 'react';
import { FC } from 'react';
import { FormRow } from '../../styled/containers/FormContainer';
import { Button } from '../../styled/buttons/Button';

interface IProps {
  isSubmitting: boolean;
}

export const SubmitButtonRow: FC<IProps> = ({ isSubmitting, children }) => (
  <FormRow style={{ marginTop: '30px' }}>
    <Button type="submit" style={{ maxWidth: 320, width: '100%' }} disabled={isSubmitting}>
      {children ? children : 'Submit'}
    </Button>
  </FormRow>
);
