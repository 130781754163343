import React, { FC } from 'react';
import { Td, Tr } from '../../styled/table/table';
import { IArbitrage } from '../../types/axios/Arbitrage';
import { formatDateTime } from '../../utils/DateTableUtils';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  data: IArbitrage;
  amountDecimals?: number;
}

const ArbOpportunitiesRow: FC<IProps> = ({ data, amountDecimals }) => {
  const { date, time } = formatDateTime(data.timestamp);
  const profit = numFormat(data.arbAmount, amountDecimals);
  const qty = numFormat(data.arbQty, amountDecimals);
  return (
    <Tr>
      <Td style={{ width: 80 }}>{data.sellOrderExchange}</Td>
      <Td style={{ width: 80 }}>{data.buyOrderExchange}</Td>
      <Td style={{ width: 80 }}>{data.ticker}</Td>
      <Td style={{ width: 150 }}>{qty}</Td>
      <Td style={{ width: 80 }}>{profit}</Td>
      <Td style={{ width: 110 }}>{data.arbPercent}</Td>
      <Td style={{ width: 150 }}>{`${date} ${time}`}</Td>
    </Tr>
  );
};

export default ArbOpportunitiesRow;
