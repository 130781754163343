import * as React from 'react';
import { FC } from 'react';
import { Table, Th, Tr } from '../../styled/table/table';
import { ISuggestion } from '../../types/axios/Suggestion';
import TransferSuggestionsRow from './TransferSuggestionsRow';

interface IProps {
  data: ISuggestion[];
}

const TransferSuggestionsTable: FC<IProps> = ({ data }) => {
  return (
    <Table>
      <tbody>
        <Tr>
          <Th>Amount</Th>
          <Th>Balance usage</Th>
          <Th>Opp count</Th>
          <Th>Avg pnl %</Th>
          <Th>From</Th>
          <Th>To</Th>
          <Th>Avg pnl %</Th>
          <Th>Opp count</Th>
          <Th>Balance usage(D/W/M)</Th>
          <Th>Current balance</Th>
        </Tr>
        {data.map(item => (
          <TransferSuggestionsRow key={`${item.from}-${item.asset}`} data={item} />
        ))}
      </tbody>
    </Table>
  );
};

export default TransferSuggestionsTable;
