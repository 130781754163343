import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { Asset } from '../../types/axios/Assets';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: Asset;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const AssetRow: FC<IProps> = ({ data, setPostError }) => {
  const [decimal, setDecimal] = useState(data.decimalPlaces.toString());

  const navigate = useNavigate();
  const { token } = useAuth();

  const [, update] = useAxios<boolean>(
    {
      url: '/asset/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onUpdate = async () => {
    setPostError(false);
    try {
      await update({
        data: {
          name: data.name,
          decimal: Number(decimal),
        },
      });
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  useEffect(() => {
    const decimalReplaced = decimal.replace(',', '.');
    if (decimalReplaced.includes('.')) {
      setDecimal(decimal.slice(0, -1));
    }
  }, [decimal]);

  return (
    <Tr>
      <Td>{data.name}</Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 300, width: '100%' }}
          value={decimal}
          type="number"
          onChange={e => {
            setDecimal(e.target.value);
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
    </Tr>
  );
};

export default AssetRow;
