import styled from 'styled-components';
import { clrTextDark, clrError, clrDividerMuted, clrBackgroundDisabled, clrWhiteMist } from '../colors';

export const Checkbox = styled.div<{ disabled?: boolean; error?: React.ReactNode }>`
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }

  label {
    position: relative;
    line-height: 25px;
    z-index: 0;
    font-size: 13px;
    box-sizing: border-box;
    color: ${clrTextDark};
    display: inline-flex;

    &:before {
      content: '';
      position: relative;
      top: 1px;
      font-size: 13px;
      z-index: 0;
      width: 16px;
      height: 16px;
      margin-right: 15px;
      font-family: Serif;
      border: 1px solid ${props => (props.error ? clrError: clrDividerMuted)};
      background-color: ${({ disabled }) => (disabled ? clrBackgroundDisabled : clrWhiteMist)};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }

  input[type='checkbox']:checked + label {
    &:before {
      background: url(${require('../../assets/icons/check-dark.png')}) no-repeat center center;
      ${({ disabled }) => (disabled ? `background-color: ${clrBackgroundDisabled};` : '')}
    }
  }
`;
