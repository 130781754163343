import * as React from 'react';
import { useState } from 'react';
import { Button } from '../styled/buttons/Button';

export interface TimeSelectButtonOption {
  getTime: () => number;
  text: string;
}

const useTimeSelectButtons = (
  options: TimeSelectButtonOption[],
  defaultOption?: TimeSelectButtonOption,
): [JSX.Element, number] => {
  const [timeMs, setTimeMs] = useState((defaultOption ?? options[0])?.getTime() ?? 0);
  const [selected, setSelected] = useState((defaultOption ?? options[0])?.text ?? '');

  const buttons = (
    <>
      {options.map(({ getTime, text }) => (
        <Button
          key={text}
          blue={selected !== text}
          style={{ marginRight: 10 }}
          onClick={() => {
            setTimeMs(getTime());
            setSelected(text);
          }}
        >
          {text}
        </Button>
      ))}
    </>
  );

  return [buttons, timeMs];
};

export default useTimeSelectButtons;
