import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Td, Th, Tr } from '../../styled/table/table';
import { IMarketTaker, MarketTaker } from '../../types/axios/MarketTaker';
import { IPlaceOrderVar } from '../../types/axios/PlaceOrder';
import { AUTH_ERROR, ILLEGAL_OPERATION_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import CentredLoader from '../miscellaneous/CenteredLoader';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';
import MarketTakerEditTickers from './MarketTakerEditTickers';
import MarketTakerEditTimes from './MarketTakerEditTimes';

const EMPTY_OPTION = { title: '', value: '' };

const MarketTakerEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [postError, setPostError] = useState('');

  const [{ data, error }] = useAxios<IPlaceOrderVar[]>({
    url: '/orders/getPlaceOrderVars',
    method: 'GET',
    headers: { authorization: token },
  });

  const [mtData] = useAxios<MarketTaker>(
    {
      url: `/market-taker/getOne/${id}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, updateMT] = useAxios<MarketTaker>(
    {
      url: '/market-taker/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (
      (error && isAxiosError(error, AUTH_ERROR)) ||
      (mtData.error && isAxiosError(mtData.error, AUTH_ERROR))
    ) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, mtData.error, navigate]);

  const [name, setName] = useState(mtData.data?.name || '');
  const [mmAccount, setMmAccount] = useState(mtData.data?.mmAccount || '');
  const [mtAccount, setMtAccount] = useState(mtData.data?.mtAccount || '');
  const [intervalMs, setIntervalMs] = useState(mtData.data?.intervalMs.toString() || '');
  const [intervalMsRandom, setIntervalMsRandom] = useState(
    mtData.data?.intervalMsRandom.toString() || '',
  );

  useEffect(() => {
    if (mtData.data) {
      setName(mtData.data.name);
      setMmAccount(mtData.data.mmAccount);
      setMtAccount(mtData.data.mtAccount);
      setIntervalMs(mtData.data.intervalMs.toString());
      setIntervalMsRandom(mtData.data.intervalMsRandom.toString());
    }
  }, [mtData.data]);

  useEffect(() => {
    const intervalMsReplaced = intervalMs.replace(',', '.');
    if (intervalMsReplaced.includes('.')) {
      setIntervalMs(intervalMs.slice(0, -1));
    }
  }, [intervalMs]);

  useEffect(() => {
    const intervalMsRandomReplaced = intervalMsRandom.replace(',', '.');
    if (intervalMsRandomReplaced.includes('.')) {
      setIntervalMsRandom(intervalMsRandom.slice(0, -1));
    }
  }, [intervalMsRandom]);

  const onSubmit = async () => {
    setPostError('');
    try {
      await updateMT({
        data: {
          id: Number(id),
          intervalMs: Number(intervalMs),
          intervalMsRandom: Number(intervalMsRandom),
          mmAccount,
          mtAccount,
          name,
        } as IMarketTaker,
      });
      navigate(RouterPaths.MARKET_TAKER);
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setPostError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setPostError('Server error.Cannot update this MM.');
      }
    }
  };

  return (
    <MainCardContainer>
      <div>
        <H2>Market Taker (edit)</H2>
        {!mtData.data ? (
          <CentredLoader />
        ) : (
          <Table>
            <tbody>
              <Tr>
                <Th>Param name</Th>
                <Th>Value</Th>
              </Tr>
              <Tr>
                <Td style={{ width: '328px' }}>Name</Td>
                <Td style={{ width: '329px' }}>
                  <InputBlock
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Market maker account</Td>
                <Td>
                  <SelectBlock
                    options={
                      data
                        ? [
                            EMPTY_OPTION,
                            ...data.map(item => ({
                              title: item.exchange,
                              value: item.exchange,
                              disabled: !item.enabled,
                            })),
                          ]
                        : [EMPTY_OPTION]
                    }
                    value={mmAccount}
                    onChange={e => setMmAccount(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Market taker account</Td>
                <Td>
                  <SelectBlock
                    options={
                      data
                        ? [
                            EMPTY_OPTION,
                            ...data.map(item => ({
                              title: item.exchange,
                              value: item.exchange,
                              disabled: !item.enabled,
                            })),
                          ]
                        : [EMPTY_OPTION]
                    }
                    value={mtAccount}
                    onChange={e => setMtAccount(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Trade interval (ms)</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={intervalMs}
                    type={'number'}
                    onChange={e => {
                      setIntervalMs(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Trade interval random (ms)</Td>
                <Td>
                  <InputBlock
                    style={{ maxWidth: 300, width: '100%' }}
                    value={intervalMsRandom}
                    type={'number'}
                    onChange={e => {
                      setIntervalMsRandom(e.target.value);
                    }}
                  />
                </Td>
              </Tr>
            </tbody>
          </Table>
        )}
        <Button
          blue
          style={{
            marginTop: 15,
            padding: 8,
            width: '100%',
          }}
          onClick={onSubmit}
        >
          Update
        </Button>
        {postError && <StatusBox style={{ color: clrError }} text={postError} />}
        {id !== undefined && <MarketTakerEditTimes mtId={id} />}
        {id !== undefined && (
          <MarketTakerEditTickers
            mtId={id}
            tickers={data?.find(data => data.exchange === mtAccount)?.tickers || []}
          />
        )}
      </div>
    </MainCardContainer>
  );
};

export default MarketTakerEdit;
