import arraySort from 'array-sort';
import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Tr } from '../../styled/table/table';
import { IMinTradePrc, MinTradePrc } from '../../types/axios/MinTradePrc';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import TH from '../form/TH';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import PrcRow from './PrcRow';
import { clrError } from '../../styled/colors';

interface sortObj {
  param: string;
  order: 'ASC' | 'DESC';
}

const PARAM_TICKER = 'ticker';
const PARAM_FROM = 'fromExchange';
const PARAM_TO = 'toExchange';

const MinTradePrcComponent: FC = () => {
  const [sortArr, setSortArr] = useState<sortObj[]>([]);
  const [postError, setPostError] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetchPrc] = useAxios<MinTradePrc[]>({
    url: '/config/getMinTradePercentages',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  const [, create] = useAxios<boolean>(
    {
      url: '/config/addMinTradePercentage',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const fetch = async () => {
    await fetchPrc();
  };

  const onAddNew = async () => {
    setPostError(false);
    try {
      await create({
        data: { fromExchange: '', toExchange: '', ticker: '', percentage: '0' } as IMinTradePrc,
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  const sortClick = (param: string) => {
    const sortObj = sortArr.find(obj => obj.param === param);
    if (sortObj?.order === 'ASC') {
      setSortArr(
        sortArr.map(obj => (obj.param === param ? { param: obj.param, order: 'DESC' } : obj)),
      );
    } else if (sortObj?.order === 'DESC') {
      setSortArr(sortArr.filter(obj => obj.param !== param));
    } else {
      setSortArr([...sortArr, { param, order: 'ASC' }]);
    }
  };

  const tickerArrow = getArrowValue(sortArr.find(i => i.param === PARAM_TICKER)?.order);
  const fromArrow = getArrowValue(sortArr.find(i => i.param === PARAM_FROM)?.order);
  const toArrow = getArrowValue(sortArr.find(i => i.param === PARAM_TO)?.order);

  const sortedData =
    sortArr.length > 0
      ? arraySort(
          [...(data ?? [])],
          ...sortArr.map(obj => getCompare(obj.param, obj.order === 'DESC')),
        )
      : data;

  return (
    <MainCardContainer>
      <div>
        {!data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2>Min Trade Percentages</H2>
              <div style={{ display: 'flex' }}>
                {postError && (
                  <div style={{ color: clrError, marginRight: 20 }}>Something went wrong..</div>
                )}
                <Button
                  blue
                  style={{
                    width: 150,
                    height: 40,
                    padding: 10,
                    marginLeft: 'auto',
                    display: 'inline-flex',
                  }}
                  onClick={onAddNew}
                >
                  Add New
                </Button>
              </div>
            </div>
            <Table>
              <tbody>
                <Tr>
                  <TH
                    style={{ cursor: 'pointer' }}
                    onClick={() => sortClick(PARAM_TICKER)}
                    arrow={tickerArrow}
                  >
                    Ticker
                  </TH>
                  <TH
                    style={{ cursor: 'pointer' }}
                    onClick={() => sortClick(PARAM_FROM)}
                    arrow={fromArrow}
                  >
                    Buy from
                  </TH>
                  <TH
                    style={{ cursor: 'pointer' }}
                    onClick={() => sortClick(PARAM_TO)}
                    arrow={toArrow}
                  >
                    Sell to
                  </TH>
                  <TH>Prc (%)</TH>
                  <TH colSpan={2}>Actions</TH>
                </Tr>
                {sortedData?.map(key => (
                  <PrcRow key={key.id} data={key} setPostError={setPostError} fetch={fetch} />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

const getArrowValue = (tickerSortOrder?: 'ASC' | 'DESC') =>
  tickerSortOrder === 'ASC' ? 'DOWN' : tickerSortOrder === 'DESC' ? 'UP' : undefined;

const getCompare = (prop: string, reverse?: boolean) => {
  return (a: any, b: any) => a[prop].localeCompare(b[prop]) * (reverse ? -1 : 1);
};

export default MinTradePrcComponent;
