import React, { FC } from 'react';
import { clrError, clrSuccess } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { IArbAction } from '../../types/axios/ArbActions';
import { formatDateTime } from '../../utils/DateTableUtils';
import { useGlobalState } from '../../hooks/useGlobalState';

const DEFAULT_QUOTE_DECIMAL = 2;
const DEFAULT_BASE_DECIMAL = 8;
interface IProps {
  data: IArbAction;
}

const ArbActionRow: FC<IProps> = ({ data }) => {
  const tickerObj = useGlobalState().tickers?.find(({ name }) => name === data.ticker);

  const baseDecimals = tickerObj?.baseDecimal ?? DEFAULT_BASE_DECIMAL;
  const quoteDeciamls = tickerObj?.quoteDecimal ?? DEFAULT_QUOTE_DECIMAL;

  const { date, time } = formatDateTime(data.createdAt);
  return (
    <Tr>
      <Td style={{ width: 80 }}>{data.buyExchange}</Td>
      <Td style={{ width: 80 }}>{data.sellExchange}</Td>
      <Td style={{ width: 120, color: data.status === 'success' ? clrSuccess : clrError }}>
        {data.status}
      </Td>
      <Td style={{ width: 150 }}>{data.buyOrderId || '-'}</Td>
      <Td style={{ width: 150 }}>{data.sellOrderId || '-'}</Td>
      <Td style={{ width: 150 }}>{`${date} ${time}`}</Td>
      <Td style={{ width: 80 }}>{data.buyOrderSymbol || data.sellOrderSymbol || '-'}</Td>
      <Td style={{ width: 80, textAlign: 'right' }}>
        <div>{getRowValue(data.sellQuantity, baseDecimals)}</div>
        <div>{getRowValue(data.buyQuantity, baseDecimals)}</div>
      </Td>
      <Td style={{ width: 100, textAlign: 'right' }}>
        <div>{getRowValue(data.avgSellPrice, quoteDeciamls)}</div>
        <div>{getRowValue(data.avgBuyPrice, quoteDeciamls)}</div>
      </Td>
      <Td style={{ width: 100, textAlign: 'right' }}>
        <div>{getRowValue(data.sellAmountNet, quoteDeciamls)}</div>
        <div>{getRowValue(data.buyAmountNet, quoteDeciamls)}</div>
      </Td>
      <Td style={{ width: 60, textAlign: 'right' }}>{getRowValue(data.pnl, quoteDeciamls)}</Td>
      <Td style={{ width: 60, textAlign: 'right' }}>{getRowValue(data.pnl_prc, 2)}</Td>
    </Tr>
  );
};

const getRowValue = (numberAsStr?: string, decimal?: number) => {
  if (!numberAsStr) {
    return '-';
  }
  const numb = Number(numberAsStr);
  return isNaN(numb) ? '-' : numb.toFixed(decimal ?? 2);
};

export default ArbActionRow;
