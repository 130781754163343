import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError, clrSuccess } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { IPlaceOrderBody, IPlaceOrderVar } from '../../types/axios/PlaceOrder';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import PlaceOrderBalances from './PlaceOrderBalances';

const rowStyle = { width: 260, marginBottom: 15 };
const MAX_PRICE_DECIMALS = 6;
const MAX_QTY_DECIMALS = 6;

const DIRECTIONS = ['buy', 'sell'];
const DEFAULT_DIRECTION = 'buy';
const EMPTY_OPTION = { title: '', value: '' };

const PlaceOrder: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [direction, setDirection] = useState(DEFAULT_DIRECTION);
  const [exchange, setExchange] = useState('');
  const [ticker, setTicker] = useState('');
  const [qty, setQty] = useState('');
  const [price, setPrice] = useState('');
  const [textError, setTextError] = useState('');
  const [placeOrderId, setPlaceOrderId] = useState('');

  const [{ data, error }] = useAxios<IPlaceOrderVar[]>({
    url: '/orders/getPlaceOrderVars',
    method: 'GET',
    headers: { authorization: token },
  });

  const [, place] = useAxios<string>(
    {
      url: '/orders/placeOrder',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  useEffect(() => {
    setTicker('');
  }, [exchange]);

  useEffect(() => {
    const qtyReplaced = qty.replace(',', '.');
    const decimals = qtyReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_QTY_DECIMALS) {
      setQty(qty.slice(0, MAX_QTY_DECIMALS - decimals.length));
    }
  }, [qty]);

  useEffect(() => {
    const priceReplaced = price.replace(',', '.');
    const decimals = priceReplaced.split('.')?.[1];
    if (decimals && decimals.length > MAX_PRICE_DECIMALS) {
      setPrice(price.slice(0, MAX_PRICE_DECIMALS - decimals.length));
    }
  }, [price]);

  const onPlaceOrder = async () => {
    setTextError('');
    setPlaceOrderId('');
    if (!direction || !exchange || !ticker || !qty || !price) {
      setTextError('Fill all fields to submit');
    } else {
      try {
        const base = ticker.split('-')[0];
        const quote = ticker.split('-')[1];
        const placeResponse = await place({
          data: {
            direction,
            exchange,
            base,
            quote,
            price,
            amount: qty,
          } as IPlaceOrderBody,
        });
        setDirection(DEFAULT_DIRECTION);
        setExchange('');
        setTicker('');
        setPrice('');
        setQty('');
        setPlaceOrderId(placeResponse.data);
      } catch (error) {
        if (isAxiosError(error, AUTH_ERROR)) {
          navigate(RouterPaths.LOG_OUT);
        } else {
          const msg = error?.response?.data?.error;
          setTextError(msg || 'Unknown server error!');
        }
      }
    }
  };

  return (
    <MainCardContainer style={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <H2>Place Order (Limit)</H2>
        {!data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <div style={rowStyle}>
              <SelectBlock
                label="Direction"
                options={DIRECTIONS.map(item => ({
                  title: item,
                  value: item,
                }))}
                value={direction}
                onChange={e => setDirection(e.target.value)}
              />
            </div>
            <div style={rowStyle}>
              <SelectBlock
                label="Exchange"
                options={
                  data
                    ? [
                        EMPTY_OPTION,
                        ...data.map(item => ({
                          title: item.exchange,
                          value: item.exchange,
                          disabled: !item.enabled,
                        })),
                      ]
                    : [EMPTY_OPTION]
                }
                value={exchange}
                onChange={e => setExchange(e.target.value)}
              />
            </div>
            <div style={rowStyle}>
              <SelectBlock
                label="Market"
                options={[
                  EMPTY_OPTION,
                  ...(data.find(item => item.exchange === exchange)?.tickers || []).map(
                    ({ name, base, quote }) => ({
                      title: name,
                      value: `${base}-${quote}`,
                    }),
                  ),
                ]}
                value={ticker}
                onChange={e => setTicker(e.target.value)}
              />
            </div>
            <div style={rowStyle}>
              <InputBlock
                label="Quantity"
                type="number"
                value={qty}
                onChange={e => {
                  setQty(e.target.value);
                }}
              />
            </div>
            <div style={rowStyle}>
              <InputBlock
                label="Price"
                type="number"
                value={price}
                onChange={e => {
                  setPrice(e.target.value);
                }}
              />
            </div>
            <div style={rowStyle}>
              <Button
                blue
                style={{
                  width: 260,
                  padding: 8,
                  marginLeft: 'auto',
                  display: 'inline-flex',
                }}
                onClick={onPlaceOrder}
              >
                Place Order
              </Button>
            </div>
            {textError && <div style={{ color: clrError, ...rowStyle }}>{textError}</div>}
            {placeOrderId && (
              <div style={{ color: clrSuccess, ...rowStyle }}>Order placed! id: {placeOrderId}</div>
            )}
          </>
        )}
      </div>
      <PlaceOrderBalances exchange={exchange} ticker={ticker} />
    </MainCardContainer>
  );
};

export default PlaceOrder;
