import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { clrError, clrSuccess } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IOpenOrders } from '../../types/axios/OpenOrders';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { StatusBox } from '../miscellaneous/status-box';
import TickerChooser from '../miscellaneous/TickerChooser';
import { useAuth } from '../../hooks/useAuth';
import OpenOrderRow from './OpenOrderRow';

const DEFAULT_TICKER = 'BTCEUR';
const tdStyle: { textAlign: 'right'; padding: string } = {
  textAlign: 'right',
  padding: '8px 10px',
};

const OpenOrders: FC = () => {
  const [postError, setPostError] = useState(false);
  const [cancelledId, setCancelledId] = useState('');
  const navigate = useNavigate();
  const { token } = useAuth();
  const [ticker, setTicker] = useState(DEFAULT_TICKER);
  const [{ data, error, loading }, fetch] = useAxios<IOpenOrders>(
    {
      url: `/orders/getOpenOrdersLastYear/${ticker}`,
      method: 'GET',
      headers: { authorization: token },
      timeout: 90000,
    },
    { useCache: false },
  );

  const onChangeTicker = (newTicker: string) => {
    setTicker(newTicker);
  };

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <H2 style={{ marginRight: 25 }}>Open Orders</H2>
          <div style={{}}>
            <TickerChooser defaultTicker={DEFAULT_TICKER} onChange={onChangeTicker} />
          </div>
        </div>
        {postError && (
          <StatusBox
            style={{ color: clrError }}
            mt={0}
            text="Failed canceling order. Maybe it's already cancelled?"
          />
        )}
        {cancelledId && (
          <StatusBox
            style={{ color: clrSuccess }}
            mt={0}
            text={`Order ${cancelledId} cancelled.`}
          />
        )}
        {error ? (
          <ErrorBox />
        ) : !data || loading ? (
          <CentredLoader />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th style={tdStyle}>Exchange</Th>
                  <Th style={tdStyle}>Order Id</Th>
                  <Th style={tdStyle}>Direction</Th>
                  <Th style={tdStyle}>Remaining Qty</Th>
                  <Th style={tdStyle}>Price</Th>
                  <Th style={tdStyle}>Amount</Th>
                  <Th style={tdStyle}>Actions</Th>
                </Tr>
                {data.orders.map(item => (
                  <OpenOrderRow
                    key={item.id}
                    data={item}
                    fetch={fetch}
                    setPostError={setPostError}
                    setCancelledId={setCancelledId}
                    ticker={ticker}
                  />
                ))}
              </tbody>
            </Table>
            {!!data.errorsInExchanges.length && (
              <StatusBox
                style={{ color: clrError }}
                text={`No response from: ${data.errorsInExchanges.join(
                  ', ',
                )}. Orders might be missing..`}
              />
            )}
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default OpenOrders;
