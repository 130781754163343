import React, { FC } from 'react';
import { clrError, clrSuccess } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { IExchangeInfo } from '../../types/axios/Exchanges';

interface IProps {
  data: IExchangeInfo;
}

const ExchangeRow: FC<IProps> = ({ data }) => {
  return (
    <Tr>
      <Td>{data.exchangeName}</Td>
      <Td>{data.tickers.join(', ')}</Td>
      <Td style={{ color: data.sockets === 'connected' ? clrSuccess : clrError }}>
        {data.sockets}
      </Td>
      <Td style={{ color: data.restAPI === 'online' ? clrSuccess : clrError }}>{data.restAPI}</Td>
      <Td style={{ color: data.restPenalty === 0 ? clrSuccess : clrError }}>
        {data.restPenalty === 0 ? 'none' : `${(data.restPenalty / 1000).toFixed(0)}s`}
      </Td>
      <Td style={{ color: data.isArbEnabled ? clrSuccess : clrError }}>
        {data.isArbEnabled ? 'enabled' : 'disabled'}
      </Td>
    </Tr>
  );
};

export default ExchangeRow;
