import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { A } from '../../styled/miscellaneous/a';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import {
  AllMMsResponse,
  IMarketMaker,
  MarketMaker,
  SplitAmtTypes,
} from '../../types/axios/MarketMaker';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';
import MarketMakerRow from './MarketMakerRow';

const REFETCH_AFTER = 60 * 1000;

const MarketMakerComponent: FC = () => {
  const [postError, setPostError] = useState('');
  const [tryCancelId, setTryCancelId] = useState<number>();

  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<AllMMsResponse[]>(
    {
      url: '/market-maker/getAll',
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, createMM] = useAxios<MarketMaker>(
    {
      url: '/market-maker/create',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, tryCancel] = useAxios<boolean>(
    {
      url: '/market-maker/tryCancelMMOrders',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  const fetchMM = async () => {
    await fetch();
  };

  const onCreate = async () => {
    setPostError('');
    try {
      await createMM({
        data: {
          name: 'New Market Maker',
          exchangeBase: '',
          exchangeMM: '',
          baseAmount: 0,
          baseAsset: '',
          quoteAsset: '',
          dedicatedAmountBids: 200,
          dedicatedQuantityAsks: 1,
          ordersOnOneSide: 5,
          priceStepsFromSpread: 0.1,
          spread: 1,
          ordersDistributionType: SplitAmtTypes.RANDOM,
          minimalProfitMargin: 0,
          pricePrecision: 2,
          pricePrecisionBase: 1,
          quantityPrecision: 8,
          slippageAllowance: 0.2,
          updateOrdersInterval: 60,
          ignoreBaseExchangeFees: false,
          orderValidTime: 15,
          ignoreBaseExchangeNotifs: false,
          altBaseAsset: '',
          altConversionExchange: '',
          altQuoteAsset: '',
          useAftermatch: true,
        } as IMarketMaker,
      });
      await fetchMM();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError('Server error. Cannot create new MM.');
      }
    }
  };

  const onTryCancel = async () => {
    setPostError('');
    setTryCancelId(undefined);
    try {
      await tryCancel({
        data: { id: tryCancelId },
      });
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError('Server error. Failed canceling orders.');
      }
    }
  };

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Market Maker</H2>
        {error ? (
          <ErrorBox />
        ) : !data ? (
          <CentredLoader />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Algorithm name</Th>
                  <Th>Enabled</Th>
                  <Th>Status</Th>
                  <Th colSpan={3}>Actions</Th>
                </Tr>
                {data.map(data => (
                  <MarketMakerRow
                    key={data.id}
                    {...data}
                    refetch={fetchMM}
                    setError={setPostError}
                    setTryCancelId={setTryCancelId}
                  />
                ))}
              </tbody>
            </Table>
            <Button
              blue
              style={{
                marginTop: 15,
                padding: 8,
                width: '100%',
              }}
              onClick={onCreate}
            >
              Add New
            </Button>
          </>
        )}
        {postError && <StatusBox style={{ color: clrError }} text={postError} />}
        {tryCancelId && (
          <div style={{ color: clrError }}>
            Click <A onClick={onTryCancel}>here</A> to try canceling orders.
          </div>
        )}
      </div>
    </MainCardContainer>
  );
};

export default MarketMakerComponent;
