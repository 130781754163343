import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IArbActions } from '../../types/axios/ArbActions';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import PaginationRow from '../miscellaneous/PaginationRow';
import { useAuth } from '../../hooks/useAuth';
import usePagination from '../../hooks/usePagination';
import ArbActionRow from './ArbActionRow';

const REFETCH_AFTER = 60 * 1000;

const ArbActions: FC = () => {
  const { limit, offset, setItemCount, ...pagProps } = usePagination();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<IArbActions>({
    url: `/arb-actions/getSome/${limit}/${offset}`,
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (data?.count !== undefined) {
      setItemCount(data.count);
    }
  }, [data, setItemCount]);

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Arbitrage Actions</H2>
        {!data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Buy from</Th>
                  <Th>Sell at</Th>
                  <Th>Status</Th>
                  <Th>Buy orderId</Th>
                  <Th>Sell orderId</Th>
                  <Th>Date</Th>
                  <Th>Ticker</Th>
                  <Th style={{ textAlign: 'right' }}>
                    <div>Sell qty </div>
                    <div>Buy qty</div>
                  </Th>
                  <Th style={{ textAlign: 'right' }}>
                    <div>Avg sell price</div>
                    <div>Avg buy price</div>
                  </Th>
                  <Th style={{ textAlign: 'right' }}>
                    <div>Sell amt net</div>
                    <div>Buy amt net</div>
                  </Th>
                  <Th style={{ textAlign: 'right' }}>pnl</Th>
                  <Th style={{ textAlign: 'right' }}>pnl(%)</Th>
                </Tr>
                {data.items.map(item => (
                  <ArbActionRow key={item.id} data={item} />
                ))}
              </tbody>
            </Table>
            <PaginationRow {...pagProps} />
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default ArbActions;
