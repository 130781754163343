import * as React from 'react';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

export interface IRoute {
  path: string;
  exact?: boolean;
  auth?: JSX.Element;
  noAuth?: JSX.Element;
  both?: JSX.Element;
}

interface IProps {
  routes: IRoute[];
  authorized?: boolean;
}

const SwitchGenerator: FC<IProps> = ({ routes, authorized }) => (
  <Routes>
    {routes.map(route => (
      <Route
        key={typeof route.path === 'string' ? route.path : route.path[0]}
        path={route.exact ? route.path : `${route.path}/*`}
        element={route.both ? route.both : authorized ? route.auth : route.noAuth}
      />
    ))}
  </Routes>
);

export default SwitchGenerator;
