import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { SOME_WRONG } from '../consts';
import { RouterPaths } from '../routes/RouterPaths';
import { FormContainer } from '../styled/containers/FormContainer';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { FormInputRowField } from './form/FormInputRow';
import { SubmitButtonRow } from './form/SubmitButtonRow';
import { useAuth } from '../hooks/useAuth';

const BAD_USER_CODE = 431;
const BAD_USER_MSG = 'Wrong username or pass.';

const initialValues: IValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

interface IValues {
  username: string;
  password: string;
}

const LoginForm: FC = () => {
  const [, loginPost] = useAxios<{ token: string }>(
    { url: '/auth/login', method: 'POST' },
    { manual: true },
  );

  const { setToken } = useAuth();

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        try {
          const response = await loginPost({ data: variables });
          setToken(response.data.token);
        } catch (error) {
          if (error?.response?.status === BAD_USER_CODE) {
            setErrors({ password: BAD_USER_MSG, username: BAD_USER_MSG });
          } else {
            setErrors({ password: SOME_WRONG, username: SOME_WRONG });
          }
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          style={{ maxWidth: '480px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormInputRowField
            id="username"
            name="username"
            label="Username"
            placeholder="Username"
          />
          <FormInputRowField
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
          />
          <SubmitButtonRow isSubmitting={isSubmitting}>Log in</SubmitButtonRow>
        </FormContainer>
      )}
    </Formik>
  );
};

const Login: FC = () => {
  const navigate = useNavigate();
  return (
    <MainCardContainer>
      <div>
        <H2>Login</H2>
        <LoginForm />
        <P style={{ textAlign: 'center', marginTop: '15px', marginBottom: '0px' }}>
          <A cursorPointer onClick={() => navigate(RouterPaths.REGISTER)}>
            Register
          </A>
        </P>
      </div>
    </MainCardContainer>
  );
};

export default Login;
