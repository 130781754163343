import React, { FC } from 'react';
import { Td, Tr } from '../../styled/table/table';
import { IMMError } from '../../types/axios/MarketMaker';
import { formatDateTime } from '../../utils/DateTableUtils';

interface IProps {
  data: IMMError;
}

const MarketMakerErrorRow: FC<IProps> = ({ data }) => {
  const { date, time } = formatDateTime(data.timeMs);
  return (
    <Tr>
      <Td style={{ width: 150 }}>{`${date} ${time}`}</Td>
      <Td style={{ width: 600 }}>{data.text}</Td>
    </Tr>
  );
};

export default MarketMakerErrorRow;
