import arraySort from 'array-sort';
import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { compareWeekDays } from '../../consts';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { H3 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IMarketTakerTime, MarketTakerTime } from '../../types/axios/MarketTaker';
import { AUTH_ERROR, ILLEGAL_OPERATION_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';
import MarketTakerEditTimesRow from './MarketTakerEditTimesRow';

interface IProps {
  mtId: string;
}

const MarketTakerEditTimes: FC<IProps> = ({ mtId }) => {
  const [postError, setPostError] = useState('');
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<MarketTakerTime[]>(
    {
      url: `/market-taker-time/getAllByMt/${mtId}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, createTime] = useAxios<MarketTakerTime>(
    {
      url: '/market-taker-time/create',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const fetchMT = async () => {
    await fetch();
  };

  const onCreate = async () => {
    setPostError('');
    try {
      await createTime({
        data: {
          mtId: Number(mtId),
          day: 'monday',
          hourStart: 0,
          hourEnd: 0,
          probability: 50,
        } as IMarketTakerTime,
      });
      await fetchMT();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setPostError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setPostError('Server error. Cannot create new MT.');
      }
    }
  };

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  if (error) {
    return <ErrorBox />;
  }
  if (!data) {
    return <CentredLoader />;
  }
  const sortedData = arraySort(
    [...data],
    [
      (a: MarketTakerTime, b: MarketTakerTime) => compareWeekDays(a.day, b.day),
      (a: MarketTakerTime, b: MarketTakerTime) => a.hourStart - b.hourStart,
    ],
  );

  return (
    <div style={{ marginTop: 15 }}>
      <H3>Time probabilities</H3>
      <Table>
        <tbody>
          <Tr>
            <Th>Weekday</Th>
            <Th>Start hour</Th>
            <Th>End hour</Th>
            <Th>Probability (%)</Th>
            <Th colSpan={2}>Actions</Th>
          </Tr>
          {sortedData.map(data => (
            <MarketTakerEditTimesRow
              key={data.id}
              data={data}
              refetch={fetchMT}
              setError={setPostError}
            />
          ))}
        </tbody>
      </Table>
      <Button
        blue
        style={{
          marginTop: 15,
          padding: 8,
          width: '100%',
        }}
        onClick={onCreate}
      >
        Add New
      </Button>
      {postError && <StatusBox style={{ color: clrError }} text={postError} />}
    </div>
  );
};

export default MarketTakerEditTimes;
