import React, { FC } from 'react';
import { clrError, clrNotifYellowish, clrSuccess } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { IOrderBookOrder } from '../../types/axios/OrderBook';
import { Ticker } from '../../types/axios/Tickers';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  data: IOrderBookOrder;
  balance?: number;
  reverse?: boolean;
  highlight?: boolean;
  tickerObj?: Ticker;
}

const OrderBookRow: FC<IProps> = ({ data, reverse, highlight, balance, tickerObj }) => {
  const lastUpdateS = (new Date().getTime() - data.updatedAt) / 1000;
  const price = numFormat(data.price, tickerObj?.quoteDecimal);
  const priceNoFee = numFormat(data.priceNoFee, tickerObj?.quoteDecimal);
  const size = numFormat(data.size, tickerObj?.baseDecimal);

  return (
    <Tr style={{ backgroundColor: highlight ? clrNotifYellowish : undefined }}>
      {reverse ? (
        <>
          <Td style={{ width: 80, color: clrSuccess }}>{price}</Td>
          <Td right style={{ width: 90 }}>
            {priceNoFee}
          </Td>
          <Td right style={{ width: 90 }}>
            {size}
          </Td>
          <Td right style={{ width: 90 }}>
            {balance ? numFormat(balance, tickerObj?.baseDecimal) : '-'}
          </Td>
          <Td right style={{ width: 80 }}>
            {data.exchangeName}
          </Td>
          <Td right style={{ width: 60 }}>
            {lastUpdateS.toFixed(1)}
          </Td>
        </>
      ) : (
        <>
          <Td right style={{ width: 60 }}>
            {lastUpdateS.toFixed(1)}
          </Td>
          <Td right style={{ width: 80 }}>
            {data.exchangeName}
          </Td>
          <Td right style={{ width: 90 }}>
            {balance ? numFormat(balance, tickerObj?.quoteDecimal) : '-'}
          </Td>
          <Td right style={{ width: 90 }}>
            {size}
          </Td>
          <Td right style={{ width: 90 }}>
            {priceNoFee}
          </Td>
          <Td right style={{ width: 80, color: clrError, textAlign: 'right' }}>
            {price}
          </Td>
        </>
      )}
    </Tr>
  );
};

export default OrderBookRow;
