import React, { FC } from 'react';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import ConfigRow from './ConfigRow';

const Configs: FC = () => {
  return (
    <MainCardContainer>
      <div>
        <H2>Configs</H2>
        <Table>
          <tbody>
            <Tr>
              <Th>Config name</Th>
              <Th>Value</Th>
              <Th>Actions</Th>
            </Tr>
            <ConfigRow
              isBool
              paramName="TradingEnabled"
              getURI="/config/getTradingEnabled"
              updateURI="/config/updateTradingEnabled"
            />
            <ConfigRow
              paramName="ArbPriceRealistic (%)"
              getURI="/config/getArbPriceRealistic"
              updateURI="/config/updateArbPriceRealistic"
            />
            <ConfigRow
              isBool
              paramName="AftermatchEnabled"
              getURI="/config/getAftermatchEnabled"
              updateURI="/config/updateAftermatchEnabled"
            />
            <ConfigRow
              paramName="MT Balance Diff (0 - disabled)"
              getURI="/config/getMtBalanceDiff"
              updateURI="/config/updateMtBalanceDiff"
            />
          </tbody>
        </Table>
      </div>
    </MainCardContainer>
  );
};

export default Configs;
