const setTimeZero = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const getDayNow = () => setTimeZero(new Date());

export const getYesterday = (currentDate?: Date) => {
  const date = currentDate ? setTimeZero(new Date(currentDate.getTime())) : getDayNow();
  date.setDate(date.getDate() - 1);
  return date;
};

export const getLastMonday = () => {
  const date = getDayNow();
  const day = date.getDay();
  date.setDate(date.getDate() - day + (day === 0 ? -6 : 1));
  return date;
};

export const getMonthNow = () => {
  const date = getDayNow();
  date.setDate(1);
  return date;
};

export const getLast7Days = (currentDate?: Date) => {
  const date = currentDate ? setTimeZero(new Date(currentDate.getTime())) : getDayNow();
  date.setDate(date.getDate() - 7);
  return date;
};

export const getLast30Days = (currentDate?: Date) => {
  const date = currentDate ? setTimeZero(new Date(currentDate.getTime())) : getDayNow();
  date.setDate(date.getDate() - 30);
  return date;
};
