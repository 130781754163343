import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { Asset } from '../../types/axios/Assets';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import AssetRow from './AssetRow';

const AssetDecimals: FC = () => {
  const [postError, setPostError] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, loading, error }] = useAxios<Asset[]>({
    url: '/asset/getAll',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Asset Decimals</H2>
        {loading || !data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Asset</Th>
                  <Th>Decimal</Th>
                  <Th>Actions</Th>
                </Tr>
                {data.map(asset => (
                  <AssetRow key={asset.name} data={asset} setPostError={setPostError} />
                ))}
              </tbody>
            </Table>
            {postError && <ErrorBox />}
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default AssetDecimals;
