import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { H4 } from '../../styled/miscellaneous/hx';
import { IBalances } from '../../types/axios/Balances';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  exchange: string;
  ticker: string;
}

const REFETCH_AFTER = 60 * 1000;

const PlaceOrderBalances: FC<IProps> = ({ exchange, ticker }) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<IBalances>({
    url: '/balances/getAll',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  const base = ticker.split('-')[0];
  const quote = ticker.split('-')[1];

  if (!exchange) {
    return <div style={{ marginLeft: 15, width: 240 }}>(Select Exchange to see balances)</div>;
  }
  return (
    <div style={{ marginLeft: 15, width: 240 }}>
      <H4>Balances at {exchange}:</H4>
      {data?.balances ? (
        <>
          {Object.entries(
            data.balances.find(balance => balance.exchange === exchange)?.assets || {},
          ).map(entry => (
            <div
              key={entry[0]}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: entry[0] === base || entry[0] === quote ? 'bold' : undefined,
              }}
            >
              <div>{entry[0]}</div>
              <div>{entry[1]}</div>
            </div>
          ))}
        </>
      ) : (
        <>Loading..</>
      )}
    </div>
  );
};

export default PlaceOrderBalances;
