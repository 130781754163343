import useAxios from 'axios-hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { CheckboxBlock } from '../form/checkbox-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  id: number;
  name: string;
  enabled: boolean;
  refetch: () => void;
  setError: (value: React.SetStateAction<string>) => void;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const MarketTakerRow: FC<IProps> = ({ id, name, enabled, refetch, setError }) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/market-taker/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [{ loading }, setEnabled] = useAxios(
    {
      url: '/market-taker/setEnabled',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    try {
      await deleteItem({
        data: { id },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setError('Server error. Unable to delete this MM.');
      }
    }
  };

  const onSetEnabled = async (enabled: boolean) => {
    try {
      await setEnabled({
        data: { id, enabled },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setError('Server error.Cannot enable this MM.');
      }
    }
  };

  return (
    <Tr>
      <Td style={{ width: 130 }}>{name}</Td>
      <Td>
        <CheckboxBlock
          style={{ display: 'flex', flexDirection: 'column' }}
          value={enabled}
          disabled={loading}
          onChange={e => {
            onSetEnabled(e.target.checked);
          }}
        />
      </Td>
      <Td>
        <Button
          style={buttonStyle}
          blue
          onClick={() => navigate(`${RouterPaths.MARKET_TAKER}/${id}`)}
        >
          Edit
        </Button>
      </Td>
      <Td>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export default MarketTakerRow;
