import React, { FC } from 'react';
import { clrBck, clrBlack } from '../../styled/colors';
import { IDynamicFee } from '../../types/axios/TradingFees';
import { Dictionary } from '../../types/Base';

interface IProps {
  data: Dictionary<IDynamicFee>;
}

const DynamicFeesTable: FC<IProps> = ({ data }) => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: clrBck,
        zIndex: 100,
        color: clrBlack,
        padding: '5px 10px',
        borderRadius: 6,
        width: 300,
        boxShadow:
          '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
      }}
    >
      {Object.keys(data).map(ticker => (
        <div key={ticker}>
          {ticker}: Taker: {data[ticker].taker}% Maker: {data[ticker].maker}%
        </div>
      ))}
    </div>
  );
};

export default DynamicFeesTable;
