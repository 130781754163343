import Axios, { AxiosError } from 'axios';
import { configure } from 'axios-hooks';
import { BACKEND_URL } from '../consts';

export const AUTH_ERROR = 430;
export const BAD_USER_PASS_ERROR = 431;
export const MISSING_ARGS_ERROR = 432;
export const ILLEGAL_OPERATION_ERROR = 433;
export const BAD_ARGS_ERROR = 434;

export const initAxios = () => {
  const axios = Axios.create({
    baseURL: BACKEND_URL,
    headers: { 'content-type': 'application/json' },
    timeout: 10 * 1000,
  });

  configure({ axios });
};

export const logError = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
};

export const isAxiosError = (error: AxiosError, errCode: number) =>
  error?.response?.status === errCode;
