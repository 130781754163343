import * as React from 'react';
import { FC } from 'react';
import { Table, Th, Tr } from '../../styled/table/table';
import { IArbOppsTableData } from '../../types/axios/Arbitrage';
import ArbOppsRow from './ArbOppsRow';

interface IProps {
  data: IArbOppsTableData[];
  exchanges: string[];
}

const ArbOppsTable: FC<IProps> = ({ data, exchanges }) => {
  return (
    <Table>
      <tbody>
        <Tr>
          <Th>
            <div>SELL TO 🠖</div>
            <div>BUY FROM 🠗</div>
          </Th>
          {exchanges.map(exchange => (
            <Th key={exchange}>{exchange}</Th>
          ))}
        </Tr>
        {exchanges.map(exchange => (
          <ArbOppsRow
            key={exchange}
            sellExchange={exchange}
            exchangeOrder={exchanges}
            data={data}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ArbOppsTable;
