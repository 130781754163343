import useAxios from 'axios-hooks';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { A } from '../../styled/miscellaneous/a';
import { Td, Tr } from '../../styled/table/table';
import { ITradingFee } from '../../types/axios/TradingFees';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';
import DynamicFeesTable from './DynamicFeesTable';

interface IProps {
  data: ITradingFee;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => Promise<void>;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const FeeRow: FC<IProps> = ({ data, setPostError, fetch }) => {
  const [exchange, setExchange] = useState(data.exchange);
  const [taker, setTaker] = useState(data.taker);
  const [maker, setMaker] = useState(data.maker);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { token } = useAuth();
  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/trading-fees/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, updateItem] = useAxios<boolean>(
    {
      url: '/trading-fees/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    setPostError(false);
    try {
      await deleteItem({
        data: { id: data.id },
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  const onUpdate = async () => {
    setPostError(false);
    try {
      await updateItem({
        data: {
          id: data.id,
          exchange,
          taker,
          maker,
        },
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  return (
    <Tr>
      <Td>
        <InputBlock
          style={{ maxWidth: 100, width: '100%' }}
          value={exchange}
          onChange={e => {
            setExchange(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 300, width: '100%' }}
          value={taker}
          onChange={e => {
            setTaker(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 100, width: '100%' }}
          value={maker}
          onChange={e => {
            setMaker(e.target.value);
          }}
        />
      </Td>
      <Td>
        {data.dynamicFees && (
          <A cursorPointer onClick={() => setIsOpen(!isOpen)}>
            Dynamic
          </A>
        )}
        {isOpen && data.dynamicFees && <DynamicFeesTable data={data.dynamicFees} />}
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
      <Td>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export default FeeRow;
