import React, { FC } from 'react';
import { Td, Tr } from '../../styled/table/table';
import { IBalanceUsage } from '../../types/axios/Balances';
import { numFormat } from '../../utils/formatUtils';
import useGetAssetObj from '../../hooks/useGetAssetObj';

interface IProps {
  data: IBalanceUsage;
}

const BalanceUsageRow: FC<IProps> = ({ data }) => {
  const decimal = useGetAssetObj(data.asset)?.decimalPlaces;
  return (
    <Tr>
      <Td style={{ width: 80 }}>{data.exchange}</Td>
      <Td style={{ width: 50 }}>{data.asset}</Td>
      <Td style={{ width: 150 }} right>
        {numFormat(data.balance, decimal)}
      </Td>
      <Td style={{ width: 120 }} right>
        {numFormat(data.usedAmt, decimal)}
      </Td>
    </Tr>
  );
};

export default BalanceUsageRow;
