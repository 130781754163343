import * as React from 'react';

import { Checkbox } from '../../styled/form/checkbox';
import { clrError } from '../../styled/colors';

interface IProps {
  style?: React.CSSProperties;
  value?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): any;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): any;
  children?: React.ReactNode;
  id?: string;
  name?: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const CheckboxBlock = ({
  style,
  id,
  required,
  value,
  onChange,
  onBlur,
  error,
  children,
  name,
  disabled,
}: IProps) => (
  <Checkbox style={style} error={error} disabled={disabled}>
    <input
      id={id}
      required={required}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      type="checkbox"
      style={error ? { borderColor: clrError } : {}}
      disabled={disabled}
    />
    <label htmlFor={id}>{children}</label>
  </Checkbox>
);

// interface FormCheckBox {
//   name: string;
//   value: boolean;
//   label: string;
//   onChange: (value: boolean) => void;
// }

// export class FormCheckBox extends React.Component<FormCheckBox>{
//   render() {
//     const p = this.props;
//     return (
//       <label>
//         <input
//           type="checkbox"
//           id={p.name}
//           name={p.name}
//           checked={p.value}
//           onChange={this.onChange}
//         />
//         {' ' + p.label}
//       </label>
//     );
//   }

//   private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     this.props.onChange(e.target.checked);
//   }
// }
