import React, { FC, useEffect, useState } from 'react';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useGlobalState } from '../../hooks/useGlobalState';
import TableSelectButton from './TableSelect/TableSelectButton';

interface IProps {
  defaultTicker?: string;
  onChange: (newTicker: string) => void;
}
const TickerChooser: FC<IProps> = ({ defaultTicker, onChange }) => {
  const [ticker, setTicker] = useState(defaultTicker ?? 'BTCEUR');
  const { errors, tickers } = useGlobalState();

  useEffect(() => {
    onChange(ticker);
  }, [ticker, onChange]);

  const onChangeTicker = (newTicker: string) => {
    setTicker(newTicker);
  };

  if (!tickers) {
    return <CentredLoader />;
  }
  if (!!errors) {
    return <ErrorBox />;
  }

  return (
    <>
      <TableSelectButton
        selectRowName="Tickers"
        onChangeValue={onChangeTicker}
        defaultSelect={ticker}
        style={{ width: 120 }}
        items={tickers.map(({ base, quote, name }) => ({
          title: `${base}/${quote}`,
          value: name,
        }))}
      />
    </>
  );
};

export default TickerChooser;
