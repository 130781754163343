import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';
import useTimeSelectButtons, { TimeSelectButtonOption } from '../../hooks/useTimeSelectButtons';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Tr } from '../../styled/table/table';
import { IResult } from '../../types/axios/Results';
import { Dictionary } from '../../types/Base';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { getDayNow, getLastMonday, getMonthNow } from '../../utils/DateUtils';
import TH from '../form/TH';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import ResultsRow from './ResultsRow';

const timeButtons: TimeSelectButtonOption[] = [
  { text: 'Today', getTime: () => getDayNow().getTime() },
  { text: 'This Week', getTime: () => getLastMonday().getTime() },
  { text: 'This Month', getTime: () => getMonthNow().getTime() },
];
const TOTAL_ASSET = 'EUR';
const TOTAL_TICKER = 'BTCEUR';

const Results: FC = () => {
  const [buttons, from] = useTimeSelectButtons(timeButtons);
  const tickers = useGlobalState().tickers;
  const { token } = useAuth();
  const navigate = useNavigate();
  const [{ data, error }, fetch] = useAxios<IResult[]>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [tickerPrices] = useAxios<Dictionary<number>>({
    url: `/ticker/getPrices`,
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  useEffect(() => {
    fetch({ url: `/arb-actions/getSum/${from}` });
  }, [from, fetch]);

  if (error) {
    return <ErrorBox />;
  }

  if (data === undefined) {
    return <CentredLoader />;
  }

  const total = data.reduce((sum, result) => {
    const quote = tickers?.find(({ name }) => name === result.ticker)?.quote;
    const pnlNumb = Number(result.pnl);
    if (quote && !isNaN(pnlNumb)) {
      if (quote === TOTAL_ASSET) {
        sum += pnlNumb;
      } else {
        const price = tickerPrices?.data?.[`${quote}${TOTAL_ASSET}`];
        if (price) {
          sum += pnlNumb * price;
        }
      }
    }
    return sum;
  }, 0);

  return (
    <MainCardContainer>
      <div>
        <H2>Results</H2>
        <div style={{ marginTop: 15, marginBottom: 10 }}>{buttons}</div>

        <Table>
          <tbody>
            <Tr>
              <TH>Ticker</TH>
              <TH right>Pnl</TH>
            </Tr>
            {data.map(data => (
              <ResultsRow key={`${data.ticker}`} data={data} />
            ))}
            <ResultsRow
              bold
              key={`total`}
              customName={`Total (${TOTAL_ASSET})`}
              data={{ ticker: TOTAL_TICKER, pnl: total.toString() }}
            />
          </tbody>
        </Table>
      </div>
    </MainCardContainer>
  );
};

export default Results;
