import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IMMError } from '../../types/axios/MarketMaker';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import MarketMakerErrorRow from './MarketMakerErrorRow';

const MarketMakerErrors: FC = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }] = useAxios<IMMError[]>(
    {
      url: `/market-maker/getMMErrors/${id}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Market Maker Errors (id:{id})</H2>
        {error ? (
          <ErrorBox />
        ) : !data ? (
          <CentredLoader />
        ) : (
          <Table>
            <tbody>
              <Tr>
                <Th>Time</Th>
                <Th>Error</Th>
              </Tr>
              {data.map((data, index) => (
                <MarketMakerErrorRow key={index} data={data} />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </MainCardContainer>
  );
};

export default MarketMakerErrors;
