import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { clrError, clrSuccess } from '../../styled/colors';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2, H3 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IOrderBook } from '../../types/axios/OrderBook';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';
import TickerChooser from '../miscellaneous/TickerChooser';
import OrderBookRow from './OrderBookRow';

const REFETCH_AFTER = 10 * 1000;
const DEFAULT_TICKER = 'BTCEUR';

const OrderBook: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [ticker, setTicker] = useState(DEFAULT_TICKER);
  const [{ data, error }, fetch] = useAxios<IOrderBook>(
    {
      url: `/order-book/getCombined/${ticker}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onChangeTicker = (newTicker: string) => {
    setTicker(newTicker);
  };

  useEffect(() => {
    fetch();
  }, [ticker, fetch]);

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  const tickerObj = useGlobalState().tickers?.find(({ name }) => name === ticker);

  const perc =
    data?.buy[0] && data?.sell[0] ? findDiffPerc(data.buy[0].price, data.sell[0].price) : 0;

  return (
    <MainCardContainer>
      <div>
        {!data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2>
                {`Combined Order Book ${ticker}`}
                {' (diff '}
                <span
                  style={{ color: perc > 0 ? clrSuccess : perc < 0 ? clrError : undefined }}
                >{`${perc > 0 ? '+' : ''}${perc.toFixed(2)}%`}</span>
                )
              </H2>
              <div style={{ marginBottom: 15 }}>
                <TickerChooser defaultTicker={DEFAULT_TICKER} onChange={onChangeTicker} />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 20 }}>
                <H3>Sell Orders</H3>
                <Table>
                  <tbody>
                    <Tr>
                      <Th right>Ago (s)</Th>
                      <Th right>Exchange</Th>
                      <Th right>Balance</Th>
                      <Th right>Size</Th>
                      <Th right>Price</Th>
                      <Th right>Price (+Fee)</Th>
                    </Tr>
                    {data.sell.map(item => (
                      <OrderBookRow
                        highlight={item.price < data.buy[0]?.price}
                        key={`${item.exchangeName}-${item.priceNoFee}`}
                        data={item}
                        balance={data.balances[item.exchangeName]?.quote}
                        tickerObj={tickerObj}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div>
                <H3>Buy Orders</H3>
                <Table>
                  <tbody>
                    <Tr>
                      <Th>Price (+Fee)</Th>
                      <Th right>Price</Th>
                      <Th right>Size</Th>
                      <Th right>Balance</Th>
                      <Th right>Exchange</Th>
                      <Th right>Ago (s)</Th>
                    </Tr>
                    {data.buy.map(item => (
                      <OrderBookRow
                        reverse
                        highlight={item.price > data.sell[0]?.price}
                        key={`${item.exchangeName}-${item.priceNoFee}`}
                        data={item}
                        balance={data.balances[item.exchangeName]?.base}
                        tickerObj={tickerObj}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default OrderBook;

const findDiffPerc = (bestBuyPrice: number, bestSellPrice: number) => {
  const priceDiff = bestBuyPrice - bestSellPrice;

  return (priceDiff * 100) / bestBuyPrice;
};
