import React, { FC, CSSProperties } from 'react';
import { Th } from '../../styled/table/table';
import { Img } from '../../styled/miscellaneous/img';
import { ITHProps } from '../../styled/table/table';

interface IProps extends ITHProps {
  style?: CSSProperties;
  onClick?: ((event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void) | undefined;
  colSpan?: number | undefined;
  arrow?: 'UP' | 'DOWN';
}

const TH: FC<IProps> = ({ children, arrow, ...rest }) => (
  <Th {...rest}>
    {children}
    {arrow && (
      <Img
        style={{ marginLeft: 10 }}
        icon={arrow === 'UP' ? 'icons/arrowhead-up' : 'icons/arrowhead-down'}
      />
    )}
  </Th>
);

export default TH;
