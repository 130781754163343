import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import PriceDecimalsRow from './PriceDecimalsRow';
import { Dictionary } from '../../types/Base';

const REFETCH_AFTER = 60 * 1000;

const PricesDecimals: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<Dictionary<number>>({
    url: '/config/getPriceDecimals',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
    return () => {
      window.clearInterval(interval);
    };
  }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <MainCardContainer>
      <div>
        <H2>Price Decimals</H2>
        {!data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <Table>
            <tbody>
              <Tr>
                <Th>Key</Th>
                <Th>Values</Th>
              </Tr>
              {Object.entries(data).map(data => (
                <PriceDecimalsRow key={data[0]} data={data} />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </MainCardContainer>
  );
};

export default PricesDecimals;
