import * as React from 'react';
import { FC, useState } from 'react';
import { Td, Tr } from '../../../../styled/table/table';
import { CheckboxBlock } from '../../../form/checkbox-block';

interface IProps {
  selected?: boolean;
  onClick: (value: string, add: boolean) => void;
  text: string;
  value?: string;
}

const TableCheckboxRow: FC<IProps> = ({ selected, onClick, text, value }) => {
  const [isSelected, setIsSelected] = useState(!!selected);

  return (
    <Tr
      underline
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setIsSelected(val => {
          onClick(value || text, !val);
          return !val;
        });
      }}
    >
      <Td>
        <CheckboxBlock value={!!isSelected} onChange={e => {}} />
      </Td>
      <Td>{text}</Td>
    </Tr>
  );
};

export default TableCheckboxRow;
