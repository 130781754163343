import React, { FC } from 'react';
import { Button } from '../../styled/buttons/Button';

interface IProps {
  canClickPrev: boolean;
  canClickNext: boolean;
  currentPage: number;
  pageCount: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

const PaginationRow: FC<IProps> = ({
  canClickNext,
  canClickPrev,
  currentPage,
  onClickNext,
  onClickPrev,
  pageCount,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
      }}
    >
      <Button
        blue
        style={{ width: 90, padding: 10 }}
        disabled={!canClickPrev}
        onClick={onClickPrev}
      >
        Prev
      </Button>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        {`Page ${currentPage} of ${pageCount}`}{' '}
      </div>
      <Button
        blue
        style={{ width: 90, padding: 10 }}
        disabled={!canClickNext}
        onClick={onClickNext}
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationRow;
