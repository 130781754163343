export const mobileSize = 900;
export const ITEMS_PER_PAGE = 15;

export const IS_PRODUCTION = !window.location.hostname.includes("localhost");

export const BACKEND_URL = IS_PRODUCTION
  ? 'https://cas-api.locbitus.com/'
  : 'http://localhost:10200';

export const SOME_WRONG = 'Something went wrong..';

export const FIVE_MIN_MS = 300000;
export const TEN_MIN_MS = FIVE_MIN_MS * 2;
export const THIRTY_MIN_MS = TEN_MIN_MS * 3;
export const HOUR_MS = THIRTY_MIN_MS * 2;
export const DAY_MS = HOUR_MS * 24;
export const WEEK_MS = DAY_MS * 7;
export const MONTH_MS = DAY_MS * 31;
export const moreDecimalsTickers = ['ETHBTC'];

export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const SUNDAY = 'sunday';
export const WEEK_DAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];
export const compareWeekDays = (aDay: string, bDay: string) => {
  const aIndex = WEEK_DAYS.indexOf(aDay);
  const bIndex = WEEK_DAYS.indexOf(bDay);
  return aIndex - bIndex;
};
