import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import useLogoutOnError from '../../hooks/useLogoutOnError';
import useStateWithLocalStorage from '../../hooks/useStateLocalStorage';
import useTimeSelectButtons, { TimeSelectButtonOption } from '../../hooks/useTimeSelectButtons';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { IArbOppsTableData } from '../../types/axios/Arbitrage';
import { getLast30Days, getLast7Days, getYesterday } from '../../utils/DateUtils';
import TableCheckboxButton from '../miscellaneous/TableCheckbox/TableCheckboxButton';
import ArbOppsTable from './ArbOppsTable';

const timeButtons: TimeSelectButtonOption[] = [
  { text: 'Yesterday', getTime: () => getYesterday().getTime() },
  { text: 'Last 7 Days', getTime: () => getLast7Days().getTime() },
  { text: 'Last 30 Days', getTime: () => getLast30Days().getTime() },
];

const ArbOppsTableComponent: FC = () => {
  const [tickers, setTickers] = useStateWithLocalStorage<string[]>('arb-opps-table-tickers', []);
  const [exchanges, setExchanges] = useStateWithLocalStorage<string[]>(
    'arb-opps-table-exchanges',
    [],
  );
  const [buttons, from] = useTimeSelectButtons(timeButtons);
  const { token } = useAuth();
  const [{ data, error }] = useAxios<{ tickers: string[]; exchanges: string[] }>({
    url: `/arbitrage/getArbVars`,
    method: 'GET',
    headers: { authorization: token },
  });

  const [tableData, fetchTableData] = useAxios<IArbOppsTableData[]>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useLogoutOnError(error);

  useEffect(() => {
    fetchTableData({
      url: `arbitrage/getArbTable/${new Date(
        from,
      ).toDateString()}/${new Date().toDateString()}/${tickers.join(',')}/${exchanges.join(',')}`,
    });
  }, [from, tickers, exchanges, fetchTableData]);

  return (
    <MainCardContainer>
      <H2>Arbitrage Opportunities Table</H2>
      {data && (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 15,
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>{buttons}</div>
            <TableCheckboxButton
              style={{ marginLeft: 10 }}
              btnText={'Exchanges'}
              items={data.exchanges.map(exchange => ({ title: exchange, value: exchange }))}
              selectRowName="Exchanges"
              defaultSelect={exchanges}
              onChangeValue={(arr: string[]) => setExchanges(arr)}
            />
            <TableCheckboxButton
              style={{ marginLeft: 10 }}
              btnText={'Tickers'}
              items={data.tickers.map(ticker => ({ title: ticker, value: ticker }))}
              selectRowName="Tickers"
              defaultSelect={tickers}
              onChangeValue={(arr: string[]) => setTickers(arr)}
            />
          </div>
        </>
      )}
      {tableData?.data && <ArbOppsTable data={tableData.data} exchanges={exchanges} />}
    </MainCardContainer>
  );
};

export default ArbOppsTableComponent;
