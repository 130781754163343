import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { RouterPaths } from '../routes/RouterPaths';
import { clrSuccess } from '../styled/colors';
import { FormContainer } from '../styled/containers/FormContainer';
import { MainCardContainer } from '../styled/containers/MainCardContainer';
import { A } from '../styled/miscellaneous/a';
import { H2 } from '../styled/miscellaneous/hx';
import { P } from '../styled/miscellaneous/p';
import { FormDescriptionRow } from './form/FormDescriptionRow';
import { FormInputRowField } from './form/FormInputRow';
import { SubmitButtonRow } from './form/SubmitButtonRow';

const initialValues: IValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

interface IValues {
  username: string;
  password: string;
}

const RegisterForm: FC = () => {
  const [, registerPost] = useAxios<{ token: string }>(
    { url: '/auth/register', method: 'POST' },
    { manual: true },
  );

  const [regSuccess, setRegSuccess] = useState(false);

  return (
    <Formik<IValues>
      initialValues={initialValues}
      onSubmit={async (variables, { setSubmitting, setErrors, setValues }) => {
        setRegSuccess(false);
        setSubmitting(true);
        try {
          await registerPost({ data: variables });
          setRegSuccess(true);
          setValues({ password: '', username: '' });
        } catch (e) {
          setErrors({ password: 'Wrong username or pass.', username: 'Wrong username or pass.' });
        }
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <FormContainer
          style={{ maxWidth: '480px', margin: '0 auto' }}
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormInputRowField
            id="username"
            name="username"
            label="Username"
            placeholder="Username"
          />
          <FormInputRowField
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
          />
          {regSuccess && (
            <FormDescriptionRow style={{ color: clrSuccess }}>
              Registration successful!
            </FormDescriptionRow>
          )}
          <SubmitButtonRow isSubmitting={isSubmitting}>Register</SubmitButtonRow>
        </FormContainer>
      )}
    </Formik>
  );
};

const Register: FC = () => {
  const navigate = useNavigate();
  return (
    <MainCardContainer>
      <div>
        <H2>Register</H2>
        <RegisterForm />
        <P style={{ textAlign: 'center', marginTop: '15px', marginBottom: '0px' }}>
          <A cursorPointer onClick={() => navigate(RouterPaths.LOG_IN)}>
            Login
          </A>
        </P>
      </div>
    </MainCardContainer>
  );
};

export default Register;
