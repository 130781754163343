import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrBluishAccent, clrGrid } from '../../styled/colors';
import { H3 } from '../../styled/miscellaneous/hx';
import { IGraphPoint } from '../../types/axios/Balances';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  assets: string[];
}

const THRITY_DAYS = 1000 * 60 * 60 * 24 * 30;
const ONE_DAY_MIN = 60 * 24;

const BalancesChart: FC<IProps> = ({ assets }) => {
  const [asset, setAsset] = useState<string>();
  const [interval] = useState(ONE_DAY_MIN);
  const [from] = useState(new Date().getTime() - THRITY_DAYS);
  const [to] = useState(new Date().getTime());
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }] = useAxios<IGraphPoint[]>({
    url: `/balances/getTotalGraph/${from}/${to}/${interval}`,
    method: 'GET',
    headers: { authorization: token },
  });

  //   useEffect(() => {
  //     const interval = window.setInterval(() => fetch(), REFETCH_AFTER);
  //     return () => {
  //       window.clearInterval(interval);
  //     };
  //   }, [fetch]);

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  return (
    <div style={{ marginTop: 15 }}>
      <H3>Total Balance Charts {asset ? `(${asset})` : ''}</H3>
      <div style={{ marginBottom: 15 }}>
        {assets.map(asset => (
          <Button key={asset} blue style={{ marginRight: 10 }} onClick={() => setAsset(asset)}>
            {asset}
          </Button>
        ))}
      </div>
      {!data ? (
        <CentredLoader />
      ) : error ? (
        <ErrorBox />
      ) : (
        asset && (
          <LineChart
            width={700}
            height={500}
            data={data.map(({ timeMs, ...rest }) => ({
              name: new Date(timeMs).getDate(),
              ...rest,
            }))}
            margin={{
              right: 10,
            }}
          >
            <CartesianGrid stroke={clrGrid} />
            <XAxis
              dataKey="name"
              label={{ value: 'Time(days)', position: 'insideBottomRight', offset: -10 }}
            />
            <YAxis
              label={{
                value: `Amount ${asset ? `(${asset})` : ''}`,
                angle: -90,
                position: 'insideLeft',
                offset: 10,
              }}
            />
            <Tooltip />
            <Legend />
            {asset && (
              <Line
                connectNulls
                type="monotone"
                dataKey={asset}
                stroke={clrBluishAccent}
                strokeWidth={2}
              />
            )}
          </LineChart>
        )
      )}
    </div>
  );
};

export default BalancesChart;
