import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { ITradingFee, ITradingFeeCreate } from '../../types/axios/TradingFees';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import FeeRow from './FeeRow';

const TradingFees: FC = () => {
  const [postError, setPostError] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, loading, error }, fetchFees] = useAxios<ITradingFee[]>({
    url: '/trading-fees/getAll',
    method: 'GET',
    headers: { authorization: token },
  });

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  const [, createFee] = useAxios<boolean>(
    {
      url: '/trading-fees/add',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const fetch = async () => {
    await fetchFees();
  };

  const onAddNew = async () => {
    setPostError(false);
    try {
      await createFee({
        data: { exchange: '', maker: '0', taker: '0' } as ITradingFeeCreate,
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  return (
    <MainCardContainer>
      <div>
        <H2>Trading Fees</H2>
        {loading || !data ? (
          <CentredLoader />
        ) : error ? (
          <ErrorBox />
        ) : (
          <>
            <Table>
              <tbody>
                <Tr>
                  <Th>Exchange name</Th>
                  <Th>Taker (%)</Th>
                  <Th>Maker (%)</Th>
                  <Th></Th>
                  <Th colSpan={2}>Actions</Th>
                </Tr>
                {data.map(item => (
                  <FeeRow key={item.id} data={item} setPostError={setPostError} fetch={fetch} />
                ))}
              </tbody>
            </Table>
            <div style={{ display: 'flex' }}>
              {postError && <ErrorBox />}
              <Button
                blue
                style={{
                  marginTop: 15,
                  width: 150,
                  padding: 10,
                  marginLeft: 'auto',
                  display: 'inline-flex',
                }}
                onClick={onAddNew}
              >
                Add New
              </Button>
            </div>
          </>
        )}
      </div>
    </MainCardContainer>
  );
};

export default TradingFees;
