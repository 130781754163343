import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import useLogoutOnError from '../../hooks/useLogoutOnError';
import useStateWithLocalStorage from '../../hooks/useStateLocalStorage';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { ISuggestion } from '../../types/axios/Suggestion';
import TableCheckboxButton from '../miscellaneous/TableCheckbox/TableCheckboxButton';
import TransferSuggestionsTable from './TransferSuggestionsTable';

const COUNT = 20;

const TransferSuggestions: FC = () => {
  const { token } = useAuth();
  const [tickers, setTickers] = useStateWithLocalStorage<string[]>(
    'transfer-suggestions-tickers',
    [],
  );
  const [exchanges, setExchanges] = useStateWithLocalStorage<string[]>(
    'transfer-suggestions-exchanges',
    [],
  );

  const [suggestions, fetch] = useAxios<ISuggestion[]>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [{ data, error }] = useAxios<{ tickers: string[]; exchanges: string[] }>({
    url: `/arbitrage/getArbVars`,
    method: 'GET',
    headers: { authorization: token },
  });
  useLogoutOnError(error);

  useLogoutOnError(error);

  useEffect(() => {
    fetch({
      url: `suggestions/getTransferSuggestions/${exchanges.join(',')}/${tickers.join(
        ',',
      )}/${COUNT}`,
    });
  }, [tickers, exchanges, fetch]);

  return (
    <MainCardContainer>
      <H2>Asset Transfer Suggestions</H2>
      {data && (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 15,
            }}
          >
            <TableCheckboxButton
              style={{ marginLeft: 10 }}
              btnText={'Exchanges'}
              items={data.exchanges.map(exchange => ({ title: exchange, value: exchange }))}
              selectRowName="Exchanges"
              defaultSelect={exchanges}
              onChangeValue={(arr: string[]) => setExchanges(arr)}
            />
            <TableCheckboxButton
              style={{ marginLeft: 10 }}
              btnText={'Tickers'}
              items={data.tickers.map(ticker => ({ title: ticker, value: ticker }))}
              selectRowName="Tickers"
              defaultSelect={tickers}
              onChangeValue={(arr: string[]) => setTickers(arr)}
            />
          </div>
        </>
      )}
      {suggestions?.data && <TransferSuggestionsTable data={suggestions.data} />}
    </MainCardContainer>
  );
};

export default TransferSuggestions;
