import useAxios from 'axios-hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError, clrSuccess, clrWarning } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { MarketMaker, MMStates } from '../../types/axios/MarketMaker';
import {
  AUTH_ERROR,
  ILLEGAL_OPERATION_ERROR,
  isAxiosError,
  MISSING_ARGS_ERROR,
} from '../../utils/axiosUtils';
import { CheckboxBlock } from '../form/checkbox-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  id: number;
  name: string;
  enabled: boolean;
  state?: MMStates;
  fallback: boolean;
  refetch: () => void;
  setError: (value: React.SetStateAction<string>) => void;
  setTryCancelId: (value: React.SetStateAction<number | undefined>) => void;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const MarketMakerRow: FC<IProps> = ({
  id,
  name,
  enabled,
  state,
  refetch,
  setError,
  setTryCancelId,
}) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/market-maker/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [{ loading }, setEnabled] = useAxios<MarketMaker>(
    {
      url: '/market-maker/setEnabled',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    try {
      await deleteItem({
        data: { id },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setError('Server error. Unable to delete this MM.');
      }
    }
  };

  const onSetEnabled = async (enabled: boolean) => {
    try {
      await setEnabled({
        data: { id, enabled },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, MISSING_ARGS_ERROR)) {
        setError('Cannot enable this MM. It has some empty parameters.');
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setError(
          `Cannot enable this MM. It still has some open orders. Maybe it's already enabled?`,
        );
        setTryCancelId(id);
      } else {
        setError('Server error.Cannot enable this MM.');
      }
    }
  };

  const { clr, text } = parseState(state);
  return (
    <Tr>
      <Td style={{ width: 130 }}>{name}</Td>
      <Td>
        <CheckboxBlock
          style={{ display: 'flex', flexDirection: 'column' }}
          value={enabled}
          disabled={loading}
          onChange={e => {
            onSetEnabled(e.target.checked);
          }}
        />
      </Td>
      <Td style={{ width: 130, color: clr }}>{text}</Td>
      <Td>
        <Button
          style={buttonStyle}
          blue
          onClick={() => navigate(`${RouterPaths.MARKET_MAKER}/${id}`)}
        >
          Edit
        </Button>
      </Td>
      <Td>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
      <Td>
        <Button
          style={buttonStyle}
          red
          onClick={() => navigate(`${RouterPaths.MARKET_MAKER_ERRORS}/${id}`)}
        >
          Errors
        </Button>
      </Td>
    </Tr>
  );
};

const parseState = (state?: MMStates, fallback?: boolean) => {
  if (state === MMStates.RUNNING) {
    return { clr: clrSuccess, text: `Running${fallback ? ' (fallback)' : ''}` };
  } else if (state === MMStates.STOPPING) {
    return { clr: clrWarning, text: 'Stopping' };
  } else if (state === MMStates.TEMP_DISABLED) {
    return { clr: clrWarning, text: 'Temporary disabled' };
  }
  return { clr: clrError, text: 'Disabled' };
};

export default MarketMakerRow;
