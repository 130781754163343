import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import SwitchGenerator, { IRoute } from '../routes/SwitchGenerator';
import ApiKeys from './ApiKeys/ApiKeys';
import ApplicationWrapper from './ApplicationWrapper';
import ArbActions from './ArbActions/ArbActions';
import ArbOpportunities from './ArbOpportunities/ArbOpportunities';
import Balances from './Balances/Balances';
import BalanceUsage from './BalanceUsage/BalanceUsage';
import Configs from './Configs/Configs';
import Exchanges from './Exchanges/Exchanges';
import Login from './Login';
import Logout from './Logout';
import MarketMakerComponent from './MarketMaker/MarketMaker';
import MarketTakerComponent from './MarketTaker/MarketTaker';
import MarketMakerEdit from './MarketMaker/MarketMakerEdit';
import MarketTakerEdit from './MarketTaker/MarketTakerEdit';
import MinQty from './MinQty/MinQty';
import MinTradePrcComponent from './MinTradePrc/MinTradePrc';
import { useAuth } from '../hooks/useAuth';
import OpenOrders from './OpenOrders/OpenOrders';
import OrderBook from './OrderBook/OrderBook';
import PlaceOrder from './PlaceOrder/PlaceOrder';
import Register from './Register';
import TradingFees from './TradingFees/TradingFees';
import MarketMakerErrors from './MarketMaker/MarketMakerErrors';
import AssetDecimals from './AssetDecimals/AssetDecimals';
import TickerDecimals from './TickerDecimals/TickerDecimals';
import Results from './Results/Results';
import ArbOppsTableComponent from './ArbOppsTable/ArbOppsTableComponent';
import ArbOppsAlterating from './ArbOppsAlterating/ArbOppsAlterating';
import TransferSuggestions from './TransferSuggestions/TransferSuggestions';
import PricesDecimals from './PriceDecimals/PricesDecimals';
import ExternalIp from './ExternalIp/ExternalIp';

const RedirectToLogin = () => <Navigate to={RouterPaths.LOG_IN} />;
const RedirectToApiKeys = () => <Navigate to={RouterPaths.API_KEYS} />;

const routes: IRoute[] = [
  {
    path: RouterPaths.API_KEYS,
    auth: <ApiKeys />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.CONFIGS,
    auth: <Configs />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.MIN_QTY,
    auth: <MinQty />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.TRADING_FEES,
    auth: <TradingFees />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.MIN_TRADE_PRC,
    auth: <MinTradePrcComponent />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.EXCHANGES,
    auth: <Exchanges />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.BALANCES,
    auth: <Balances />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ARB_ACTIONS,
    auth: <ArbActions />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ARB_OPPORTUNITIES,
    auth: <ArbOpportunities />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ORDER_BOOK,
    auth: <OrderBook />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.PLACE_ORDER,
    auth: <PlaceOrder />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.OPEN_ORDERS,
    auth: <OpenOrders />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.MARKET_MAKER,
    auth: <MarketMakerComponent />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: `${RouterPaths.MARKET_MAKER}/:id`,
    auth: <MarketMakerEdit />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: `${RouterPaths.MARKET_MAKER_ERRORS}/:id`,
    auth: <MarketMakerErrors />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.BALANCE_USAGE,
    auth: <BalanceUsage />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.MARKET_TAKER,
    auth: <MarketTakerComponent />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: `${RouterPaths.MARKET_TAKER}/:id`,
    auth: <MarketTakerEdit />,
    exact: true,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ASSET_DECIMALS,
    auth: <AssetDecimals />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.TICKER_DECIMALS,
    auth: <TickerDecimals />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.RESULTS,
    auth: <Results />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ARB_OPPS_TABLE,
    auth: <ArbOppsTableComponent />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.ARB_OPPS_ALTERATING,
    auth: <ArbOppsAlterating />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.TRANSFER_SUGGESTIONS,
    auth: <TransferSuggestions />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.PRICE_DECIMALS,
    auth: <PricesDecimals />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.EXTERNAL_IP,
    auth: <ExternalIp />,
    noAuth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.LOG_IN,
    noAuth: <Login />,
    auth: <RedirectToApiKeys />,
  },
  {
    path: RouterPaths.REGISTER,
    noAuth: <Register />,
    auth: <RedirectToLogin />,
  },
  {
    path: RouterPaths.LOG_OUT,
    both: <Logout />,
  },
  {
    path: RouterPaths.ROOT,
    auth: <RedirectToApiKeys />,
    noAuth: <RedirectToLogin />,
  },
];

const ApplicationContext: FC = () => {
  const { token } = useAuth();

  return (
    <ApplicationWrapper>
      <SwitchGenerator authorized={!!token} routes={routes} />
    </ApplicationWrapper>
  );
};

export default ApplicationContext;
