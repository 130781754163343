export enum SplitAmtTypes {
  RANDOM = 'rnd',
  RANDOM_50 = 'rnd50',
  EQUAL = 'equal',
}

export interface IMarketMaker {
  name: string;
  exchangeBase: string;
  exchangeMM: string;
  baseAmount: number;
  baseAsset: string;
  quoteAsset: string;
  dedicatedAmountBids: number;
  dedicatedQuantityAsks: number;
  ordersOnOneSide: number;
  priceStepsFromSpread: number;
  spread: number;
  ordersDistributionType: SplitAmtTypes;
  minimalProfitMargin: number;
  pricePrecision: number;
  pricePrecisionBase: number;
  quantityPrecision: number;
  slippageAllowance: number;
  updateOrdersInterval: number;
  ignoreBaseExchangeFees: boolean;
  orderValidTime: number;
  ignoreBaseExchangeNotifs: boolean;
  altBaseAsset: string;
  altQuoteAsset: string;
  altConversionExchange: string;
  exchangeBaseFallback: string;
  useAftermatch:boolean;
}

export interface IMarketMakerUpdate extends IMarketMaker {
  id: number;
}

export interface MarketMaker extends IMarketMakerUpdate {
  createdAt: Date;
  enabled: boolean;
}

export enum MMStates {
  RUNNING,
  STOPPING,
  TEMP_DISABLED,
}

export interface AllMMsResponse {
  fallback: boolean;
  state?: MMStates;
  name: string;
  enabled: boolean;
  id: number;
}

export interface IMMError {
  text: string;
  timeMs: number;
}
