import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import { AUTH_ERROR, isAxiosError } from '../utils/axiosUtils';

const useLogoutOnError = (error: AxiosError<any> | undefined) => {
  const navigate = useNavigate();
  if (error && isAxiosError(error, AUTH_ERROR)) {
    navigate(RouterPaths.LOG_OUT);
  }
};

export default useLogoutOnError;
