import React, { FC } from 'react';
import { clrError } from '../../styled/colors';
import { Td, Tr } from '../../styled/table/table';
import { IBalance } from '../../types/axios/Balances';
import { useGlobalState } from '../../hooks/useGlobalState';

interface IProps {
  data: IBalance;
  assetsToShow: string[];
}

const BalanceRow: FC<IProps> = ({ data, assetsToShow }) => {
  const { assets } = useGlobalState();

  return (
    <Tr>
      <Td>{data.exchange}</Td>
      {data.error ? (
        <Td colSpan={Object.keys(data.assets).length} style={{ color: clrError }}>
          API Error: Failed fetching balance.
        </Td>
      ) : (
        assetsToShow.map(key => (
          <Td key={key} style={{ width: 130 }}>
            {data.assets[key]?.toFixed(
              assets?.find(({ name }) => name === key)?.decimalPlaces ?? 8,
            ) || '-'}
          </Td>
        ))
      )}
    </Tr>
  );
};

export default BalanceRow;
