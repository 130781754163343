export enum RouterPaths {
  ROOT = '/',
  API_KEYS = '/api-keys',
  TRADING_FEES = '/trading-fees',
  LOG_OUT = '/logout',
  LOG_IN = '/login',
  REGISTER = '/register',
  CONFIGS = '/configs',
  MIN_QTY = '/min-qty',
  MIN_TRADE_PRC = '/min-trade-percentages',
  EXCHANGES = '/exchanges',
  BALANCES = '/balances',
  ARB_ACTIONS = '/arbitrage-actions',
  ARB_OPPORTUNITIES = '/arbitrage-opportunities',
  ORDER_BOOK = '/order-book',
  PLACE_ORDER = '/place-order',
  MARKET_MAKER = '/market-maker',
  MARKET_MAKER_ERRORS = '/market-maker-errors',
  OPEN_ORDERS = '/open-orders',
  BALANCE_USAGE = '/balance-usage',
  MARKET_TAKER = '/market-taker',
  ASSET_DECIMALS = '/asset-decimals',
  TICKER_DECIMALS = '/ticker-decimals',
  RESULTS = '/results',
  ARB_OPPS_TABLE = '/arbitrage-opportunities-table',
  ARB_OPPS_ALTERATING = '/arbitrage-opportunities-alterating',
  TRANSFER_SUGGESTIONS = '/transfer-suggestions',
  PRICE_DECIMALS = '/price-decimals',
  EXTERNAL_IP = '/external-ip',
}

export const exact = true;
