import { useState } from 'react';
import { ITEMS_PER_PAGE } from '../consts';

const usePagination = (itemsPerPage: number = ITEMS_PER_PAGE) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemCount, setItemCount] = useState(1);

  const pageCount = Math.ceil(itemCount / itemsPerPage);
  const limit = itemsPerPage;
  const offset = itemsPerPage * (currentPage - 1);
  const canClickNext = currentPage < pageCount;
  const canClickPrev = currentPage > 1;

  const onClickNext = () => {
    if (canClickNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickPrev = () => {
    if (canClickPrev) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onClickFirstPage = () => {
    setCurrentPage(1);
  };

  return {
    limit,
    offset,
    currentPage,
    pageCount,
    canClickNext,
    canClickPrev,
    onClickNext,
    onClickPrev,
    setItemCount,
    onClickFirstPage,
  };
};

export default usePagination;
