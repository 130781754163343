import React, { FC } from 'react';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Td, Tr } from '../../styled/table/table';
import { IArbOppsTableData } from '../../types/axios/Arbitrage';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  sellExchange: string;
  exchangeOrder: string[];
  data: IArbOppsTableData[];
}

const ArbOppsRow: FC<IProps> = ({ data, sellExchange, exchangeOrder }) => {
  const rowData = data.filter(data => data.sellExchange === sellExchange);
  const { tickers } = useGlobalState();
  return (
    <Tr>
      <Td style={{ fontWeight: 600 }}>{sellExchange}</Td>
      {exchangeOrder.map(buyExchange => {
        const arbOpps = rowData.filter(opp => opp.buyExchange === buyExchange);
        if (!arbOpps.length) {
          return <Td key={buyExchange}>X</Td>;
        }
        return (
          <Td key={buyExchange}>
            {arbOpps.map(
              (
                { oppCount, avgPerc, avgPnl, ticker, bestAvgPerc, bestAvgPnl, bestOppCount },
                index,
                arr,
              ) => {
                const tickerObj = tickers?.find(({ name }) => name === ticker);
                const pnlDecimals = tickerObj?.quoteDecimal || 2;
                const quoteAsset = tickerObj?.quote;
                return (
                  <div
                    key={ticker}
                    style={{ borderBottom: arr.length - 1 === index ? undefined : '1px solid' }}
                  >
                    <div>
                      <span style={{ fontWeight: 600 }}>{ticker}</span>
                      {`: ${oppCount}`}
                    </div>
                    <div>{`All: ${avgPerc.toFixed(2)}% ${numFormat(
                      avgPnl,
                      pnlDecimals,
                    )} ${quoteAsset}`}</div>
                    <div>{`Best ${
                      !!bestOppCount ? Math.round((bestOppCount / oppCount) * 100) : 0
                    }%: ${bestAvgPerc.toFixed(2)}% ${numFormat(
                      bestAvgPnl,
                      pnlDecimals,
                    )} ${quoteAsset}`}</div>
                  </div>
                );
              },
            )}
          </Td>
        );
      })}
    </Tr>
  );
};

export default ArbOppsRow;
