import useAxios from 'axios-hooks';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WEEK_DAYS } from '../../consts';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { MarketTakerTime } from '../../types/axios/MarketTaker';
import { AUTH_ERROR, ILLEGAL_OPERATION_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: MarketTakerTime;
  refetch: () => void;
  setError: (value: React.SetStateAction<string>) => void;
}

const EMPTY_OPTION = { title: '', value: '' };
const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const MarketTakerEditTimesRow: FC<IProps> = ({ data, refetch, setError }) => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [day, setDay] = useState(data.day);
  const [hourStart, setHourStart] = useState(data.hourStart.toString());
  const [hourEnd, setHourEnd] = useState(data.hourEnd.toString());
  const [probability, setProbability] = useState(data.probability.toString());

  const [, deleteItem] = useAxios<boolean>(
    {
      url: '/market-taker-time/delete',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, update] = useAxios(
    {
      url: '/market-taker-time/update',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    try {
      await deleteItem({
        data: { id: data.id },
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setError('Server error. Unable to delete this Time.');
      }
    }
  };

  const onUpdate = async () => {
    try {
      await update({
        data: {
          id: data.id,
          day,
          hourStart: Number(hourStart),
          hourEnd: Number(hourEnd),
          mtId: data.mtId,
          probability: Number(probability),
        } as MarketTakerTime,
      });
      refetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setError('Server error.');
      }
    }
  };

  return (
    <Tr>
      <Td>
        <SelectBlock
          style={{ width: 120 }}
          options={
            data
              ? [
                  EMPTY_OPTION,
                  ...WEEK_DAYS.map(day => ({
                    title: day,
                    value: day,
                  })),
                ]
              : [EMPTY_OPTION]
          }
          value={day}
          onChange={e => setDay(e.target.value)}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 70, width: '100%' }}
          type="number"
          value={hourStart}
          onChange={e => {
            setHourStart(Number(e.target.value).toFixed(0));
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 70, width: '100%' }}
          type="number"
          value={hourEnd}
          onChange={e => {
            setHourEnd(Number(e.target.value).toFixed(0));
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 70, width: '100%' }}
          type="number"
          value={probability}
          onChange={e => {
            setProbability(e.target.value);
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
      <Td style={{ width: 160 }}>
        <Button style={buttonStyle} red onClick={onDelete}>
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export default MarketTakerEditTimesRow;
