import useAxios from 'axios-hooks';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { MinTradePrc } from '../../types/axios/MinTradePrc';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import { useAuth } from '../../hooks/useAuth';

interface IProps {
  data: MinTradePrc;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => Promise<void>;
}

const buttonStyle = { padding: '5px 10px', maxWidth: 80 };

const PrcRow: FC<IProps> = ({ data, setPostError, fetch }) => {
  const [ticker, setTicker] = useState(data.ticker);
  const [from, setFrom] = useState(data.fromExchange);
  const [to, setTo] = useState(data.toExchange);
  const [prc, setPrc] = useState(data.percentage);

  const navigate = useNavigate();
  const { token } = useAuth();
  const [, deleteKey] = useAxios<boolean>(
    {
      url: '/config/removeMinTradePercentage',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const [, updateKey] = useAxios<boolean>(
    {
      url: '/config/updateMinTradePercentage',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onDelete = async () => {
    setPostError(false);
    try {
      await deleteKey({
        data: { id: data.id },
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };

  const onUpdate = async () => {
    setPostError(false);
    try {
      await updateKey({
        data: {
          id: data.id,
          fromExchange: from,
          toExchange: to,
          ticker,
          percentage: prc,
        },
      });
      await fetch();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
  };
  let disabled = false;
  if (
    data.ticker === 'default' &&
    data.fromExchange === 'default' &&
    data.toExchange === 'default'
  ) {
    disabled = true;
  }
  return (
    <Tr>
      <Td>
        <InputBlock
          disabled={disabled}
          style={{ maxWidth: 100, width: '100%' }}
          value={ticker}
          onChange={e => {
            setTicker(e.target.value.toUpperCase());
          }}
        />
      </Td>
      <Td>
        <InputBlock
          disabled={disabled}
          style={{ maxWidth: 300, width: '100%' }}
          value={from}
          onChange={e => {
            setFrom(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          disabled={disabled}
          style={{ maxWidth: 100, width: '100%' }}
          value={to}
          onChange={e => {
            setTo(e.target.value);
          }}
        />
      </Td>
      <Td>
        <InputBlock
          style={{ maxWidth: 120, width: '100%' }}
          value={prc}
          onChange={e => {
            setPrc(e.target.value);
          }}
        />
      </Td>
      <Td>
        <Button style={buttonStyle} blue onClick={onUpdate}>
          Update
        </Button>
      </Td>
      <Td>
        {!disabled && (
          <Button style={buttonStyle} red onClick={onDelete}>
            Delete
          </Button>
        )}
      </Td>
    </Tr>
  );
};

export default PrcRow;
