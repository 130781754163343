import useAxios from 'axios-hooks';
import React, { FC, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import useLogoutOnError from '../../hooks/useLogoutOnError';
import useTimeSelectButtons, { TimeSelectButtonOption } from '../../hooks/useTimeSelectButtons';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { IArbOppAlterating } from '../../types/axios/Arbitrage';
import { getLast30Days, getLast7Days, getYesterday } from '../../utils/DateUtils';
import ArbOppsAlteratingTable from './ArbOppsAlteratingTable';

const timeButtons: TimeSelectButtonOption[] = [
  { text: 'Yesterday', getTime: () => getYesterday().getTime() },
  { text: 'Last 7 Days', getTime: () => getLast7Days().getTime() },
  { text: 'Last 30 Days', getTime: () => getLast30Days().getTime() },
];

const ArbOppsAlterating: FC = () => {
  const [buttons, from] = useTimeSelectButtons(timeButtons);
  const { token } = useAuth();

  const [{ data, error }, fetch] = useAxios<IArbOppAlterating[]>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useLogoutOnError(error);

  useEffect(() => {
    fetch({
      url: `arbitrage/getArbAlterating/${new Date(
        from,
      ).toDateString()}/${new Date().toDateString()}`,
    });
  }, [from, fetch]);

  return (
    <MainCardContainer>
      <H2>Alternating Arbitrage Opportunities</H2>
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: 15,
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>{buttons}</div>
      </div>
      {data && <ArbOppsAlteratingTable data={data} />}
    </MainCardContainer>
  );
};

export default ArbOppsAlterating;
