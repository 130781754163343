import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../routes/RouterPaths';
import { HeaderButton } from '../styled/buttons/HeaderButton';
import { DropdownHeaderMenu } from '../styled/containers/DropdownHeaderMenu';
import { HeaderContainer } from '../styled/containers/HeaderContainer';
import { Div } from '../styled/miscellaneous/div';
import { Img } from '../styled/miscellaneous/img';
import OutsideClose from './miscellaneous/OutsideClose';
import { useAuth } from '../hooks/useAuth';

const buttons = [
  { text: 'API KEYS', path: RouterPaths.API_KEYS },
  { text: 'CONFIGS', path: RouterPaths.CONFIGS },
  { text: 'MIN QTY', path: RouterPaths.MIN_QTY },
  { text: 'TRADING FEES', path: RouterPaths.TRADING_FEES },
  { text: 'MIN TRADE PRC', path: RouterPaths.MIN_TRADE_PRC },
  { text: 'EXCHANGES', path: RouterPaths.EXCHANGES },
  { text: 'BALANCES', path: RouterPaths.BALANCES },
  { text: 'ARB ACTIONS', path: RouterPaths.ARB_ACTIONS },
  { text: 'ARB OPPORTUNITIES', path: RouterPaths.ARB_OPPORTUNITIES },
  { text: 'ORDER BOOK', path: RouterPaths.ORDER_BOOK },
  { text: 'PLACE ORDER', path: RouterPaths.PLACE_ORDER },
  { text: 'OPEN ORDERS', path: RouterPaths.OPEN_ORDERS },
  { text: 'MARKET MAKER', path: RouterPaths.MARKET_MAKER },
  { text: 'BALANCE USAGE', path: RouterPaths.BALANCE_USAGE },
  { text: 'MARKET TAKER', path: RouterPaths.MARKET_TAKER },
  { text: 'ASSET DECIMALS', path: RouterPaths.ASSET_DECIMALS },
  { text: 'TICKER DECIMALS', path: RouterPaths.TICKER_DECIMALS },
  { text: 'RESULTS', path: RouterPaths.RESULTS },
  { text: 'ARB OPP TABLE', path: RouterPaths.ARB_OPPS_TABLE },
  { text: 'ALTER ARB OPP', path: RouterPaths.ARB_OPPS_ALTERATING },
  { text: 'SUGGESTIONS', path: RouterPaths.TRANSFER_SUGGESTIONS },
  { text: 'PRICE DECIMALS', path: RouterPaths.PRICE_DECIMALS },
  { text: 'EXTERNAL IP', path: RouterPaths.EXTERNAL_IP },
];

const Header: FC = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { token } = useAuth();
  const authorized = !!token;
  return (
    <HeaderContainer>
      <Div isHiddenMobile>
        {buttons.map(({ path, text }) => (
          <HeaderButton
            key={text}
            transparent
            roundCorners
            onClick={() => {
              if (path) {
                navigate(path);
              }
            }}
          >
            {text}
          </HeaderButton>
        ))}
      </Div>
      <OutsideClose onOutsideTrigger={() => setMenuOpen(false)}>
        <Div
          isHiddenDesktop
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          pointer
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Img icon="icons/menu" pointer style={{ margin: '0 5px 0 5px' }}></Img>
        </Div>
        {menuOpen && (
          <DropdownHeaderMenu isHiddenDesktop>
            {buttons.map(({ path, text }) => (
              <HeaderButton
                key={text}
                transparent
                roundCorners
                width="200px"
                height="45px"
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setMenuOpen(false);
                  if (path) {
                    navigate(path);
                  }
                }}
              >
                {text}
              </HeaderButton>
            ))}
          </DropdownHeaderMenu>
        )}
      </OutsideClose>
      <div>
        <HeaderButton
          transparent
          whiteBorder
          width="150px"
          padding="10px"
          onClick={() => {
            if (authorized) {
              navigate(RouterPaths.LOG_OUT);
            } else {
              navigate(RouterPaths.LOG_IN);
            }
          }}
        >
          {authorized ? 'LOG OUT' : 'LOG IN'}
        </HeaderButton>
      </div>
    </HeaderContainer>
  );
};

export default Header;
