import * as React from 'react';
import { FC, useState, useRef, useEffect } from 'react';
import { MainCardContainer } from '../../../../styled/containers/MainCardContainer';
import { Table, Th, Tr } from '../../../../styled/table/table';
import { InputBlock } from '../../../form/input-block';
import TableSelectRow from './TableSelectRow';

export interface ITableSelectProps {
  onChangeValue?: (newValue: string) => void;
  items: { title: string; value: string }[];
  defaultSelect?: string;
  selectRowName?: string;
  isOpen?: boolean;
}

const TableSelect: FC<ITableSelectProps> = ({
  onChangeValue,
  items,
  defaultSelect,
  selectRowName,
  isOpen,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedItem, setSelectedItem] = useState<string>(defaultSelect || '');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const onClick = (value: string) => {
    setSelectedItem(value);

    onChangeValue && onChangeValue(value);
  };

  return (
    <>
      <MainCardContainer
        style={{
          flex: 1,
          margin: 0,
          padding: 0,
          flexDirection: 'column',
          alignSelf: 'auto',
          maxWidth: '100%',
          alignItems: 'stretch',
        }}
      >
        <InputBlock
          style={{ width: 150, margin: 5 }}
          placeholder="Search"
          value={filter}
          ref={inputRef}
          onChange={e => {
            setFilter(e.target.value);
          }}
        />
        <Table>
          <tbody>
            <Tr>
              <Th style={{ padding: '8px 13px' }}>{selectRowName || 'Item'}</Th>
            </Tr>
          </tbody>
        </Table>
        <div style={{ display: 'flex', flexBasis: '0px', flexGrow: 1, overflow: 'hidden auto' }}>
          <Table style={{ width: '100%' }}>
            <tbody>
              {items
                .filter(({ value }) => value.includes(filter.replace('/', '').toUpperCase()))
                .map(({ title, value }) => (
                  <TableSelectRow
                    key={value}
                    text={title}
                    value={value}
                    onClick={onClick}
                    selected={value === selectedItem}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      </MainCardContainer>
    </>
  );
};

export default TableSelect;
