import React, { FC } from 'react';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Td, Tr } from '../../styled/table/table';
import { ISuggestion } from '../../types/axios/Suggestion';
import { numFormat } from '../../utils/formatUtils';

interface IProps {
  data: ISuggestion;
}

const TransferSuggestionsRow: FC<IProps> = ({ data }) => {
  const { amount, asset, balanceUsage, from, to, opps } = data;
  const { assets } = useGlobalState();
  const assetObj = assets?.find(({ name }) => name === asset);
  const decimals = assetObj?.decimalPlaces ?? 2;
  return (
    <Tr>
      <Td>
        {numFormat(amount, decimals)} {asset}
      </Td>
      <Td>
        <div>
          Last day: {numFormat(balanceUsage.day, decimals)} {asset}
        </div>
        <div>
          Last week: {numFormat(balanceUsage.week, decimals)} {asset}
        </div>
        <div>
          Last month: {numFormat(balanceUsage.month, decimals)} {asset}
        </div>
      </Td>
      <Td>{opps ? opps.oppCount : '-'}</Td>
      <Td>{opps ? `${opps.avgPerc.toFixed(2)}%` : '-'}</Td>
      <Td>
        {from} {'->'}
      </Td>
      <Td>
        {to.map(({ name }) => (
          <div key={name}>{name}</div>
        ))}
      </Td>
      <Td>
        {to.map(({ name, opps }) => (
          <div key={name}>{opps ? `${opps.avgPerc.toFixed(2)}%` : '-'}</div>
        ))}
      </Td>
      <Td>
        {to.map(({ name, opps }) => (
          <div key={name}>{opps ? opps.oppCount : '-'}</div>
        ))}
      </Td>
      <Td>
        {to.map(({ name, balanceUsage }) => (
          <div key={name}>
            {numFormat(balanceUsage.day, decimals)}/{numFormat(balanceUsage.week, decimals)}/
            {numFormat(balanceUsage.month, decimals)} {asset}
          </div>
        ))}
      </Td>
      <Td>
        {to.map(({ name, balance }) => (
          <div key={name}>
            {numFormat(balance, decimals)} {asset}
          </div>
        ))}
      </Td>
    </Tr>
  );
};

export default TransferSuggestionsRow;
