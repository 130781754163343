import { AxiosResponse } from 'axios';
import useAxios from 'axios-hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { Td, Tr } from '../../styled/table/table';
import { ICancelOrder, IOpenOrder, IOpenOrders } from '../../types/axios/OpenOrders';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { numFormat } from '../../utils/formatUtils';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../hooks/useGlobalState';

const tdStyle: { textAlign: 'right'; padding: string } = {
  textAlign: 'right',
  padding: '8px 10px',
};

interface IProps {
  ticker: string;
  data: IOpenOrder;
  setPostError: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelledId: React.Dispatch<React.SetStateAction<string>>;
  fetch: () => Promise<AxiosResponse<IOpenOrders>>;
}

const OpenOrderRow: FC<IProps> = ({ data, ticker, fetch, setPostError, setCancelledId }) => {
  const tickerObj = useGlobalState().tickers?.find(({ name }) => name === ticker);
  const { id, side, remaining, price, exchangeName, symbol } = data;
  const value = price * remaining;

  const navigate = useNavigate();
  const { token } = useAuth();
  const [, cancelOrder] = useAxios<boolean>(
    {
      url: '/orders/cancelOrder',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const onCancel = async () => {
    setPostError(false);
    setCancelledId('');
    try {
      await cancelOrder({
        data: { exchangeName, orderId: id, price: price.toString(), side, symbol } as ICancelOrder,
      });
      setCancelledId(id);
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else {
        setPostError(true);
      }
    }
    try {
      await fetch();
    } catch (e) {}
  };

  return (
    <Tr>
      <Td style={{ ...tdStyle, width: 80 }}>{exchangeName}</Td>
      <Td style={tdStyle}>{id}</Td>
      <Td style={tdStyle}>{side}</Td>
      <Td style={tdStyle}>{numFormat(remaining, tickerObj?.baseDecimal)}</Td>
      <Td style={tdStyle}>{numFormat(price, tickerObj?.quoteDecimal)}</Td>
      <Td style={tdStyle}>{numFormat(value, tickerObj?.quoteDecimal)}</Td>
      <Td>
        <Button style={{ padding: '5px 10px', maxWidth: 80 }} red onClick={onCancel}>
          Cancel
        </Button>
      </Td>
    </Tr>
  );
};

export default OpenOrderRow;
