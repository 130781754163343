import { zeroPadded } from './formatUtils';

export const formatDateTime = (dateString: string | number): { date: string; time: string } => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return { date: '(date not found)', time: '(time not found)' };
  }

  return {
    date: `${date.getFullYear()}-${zeroPadded(date.getMonth() + 1)}-${zeroPadded(date.getDate())}`,
    time: `${zeroPadded(date.getHours())}:${zeroPadded(date.getMinutes())}:${zeroPadded(
      date.getSeconds(),
    )}`,
  };
};
