import arraySort from 'array-sort';
import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DAY_MS, MONTH_MS, WEEK_MS } from '../../consts';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { MainCardContainer } from '../../styled/containers/MainCardContainer';
import { H2 } from '../../styled/miscellaneous/hx';
import { Table, Tr } from '../../styled/table/table';
import { IBalanceUsages } from '../../types/axios/Balances';
import { AUTH_ERROR, isAxiosError } from '../../utils/axiosUtils';
import { InputBlock } from '../form/input-block';
import SelectBlock from '../form/SelectBlock';
import TH from '../form/TH';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { useAuth } from '../../hooks/useAuth';
import BalanceUsageRow from './BalanceUsageRow';

interface sortObj {
  param: string;
  order: 'ASC' | 'DESC';
}

const PARAM_BALANCE = 'balance';
const PARAM_USED_AMT = 'usedAmt';
const DEFAULT_DROPDOWN = 'all';

const BalanceUsage: FC = () => {
  const [sortArr, setSortArr] = useState<sortObj[]>([]);
  const [duration, setDuration] = useState(DAY_MS);
  const [balanceFilter, setBalanceFilter] = useState('0');
  const [usedAmtFilter, setUsedAmtFilter] = useState('0');
  const [asset, setAsset] = useState(DEFAULT_DROPDOWN);
  const { token } = useAuth();
  const navigate = useNavigate();
  const [{ data, error }, fetch] = useAxios<IBalanceUsages>(
    {
      method: 'GET',
      headers: { authorization: token },
    },
    { manual: true },
  );

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  useEffect(() => {
    const timeMs = Date.now();
    fetch({ url: `/balances/getBalanceUsage/${timeMs - duration}/${timeMs}` });
  }, [duration, fetch]);

  const sortClick = (param: string) => {
    const sortObj = sortArr.find(obj => obj.param === param);
    if (sortObj?.order === 'ASC') {
      setSortArr(
        sortArr.map(obj => (obj.param === param ? { param: obj.param, order: 'DESC' } : obj)),
      );
    } else if (sortObj?.order === 'DESC') {
      setSortArr(sortArr.filter(obj => obj.param !== param));
    } else {
      setSortArr([...sortArr, { param, order: 'ASC' }]);
    }
  };

  if (error) {
    return <ErrorBox />;
  }

  if (data === undefined) {
    return <CentredLoader />;
  }

  const balanceArrow = getArrowValue(sortArr.find(i => i.param === PARAM_BALANCE)?.order);
  const usedAmtArrow = getArrowValue(sortArr.find(i => i.param === PARAM_USED_AMT)?.order);

  const filteredData = data.balances.filter(
    ({ balance, usedAmt, ...rest }) =>
      balance >= Number(balanceFilter) &&
      usedAmt >= Number(usedAmtFilter) &&
      (asset === DEFAULT_DROPDOWN || rest.asset === asset),
  );
  const sortedData =
    sortArr.length > 0
      ? arraySort(
          [...filteredData],
          ...sortArr.map(obj => getCompare(obj.param, obj.order === 'DESC')),
        )
      : filteredData;

  return (
    <MainCardContainer>
      <div>
        <H2>Balance Usage</H2>
        <div style={{ display: 'flex' }}>
          <SelectBlock
            style={{ marginLeft: 10, marginTop: 'auto', width: 110, display: 'flex' }}
            label="Asset"
            options={[
              { title: DEFAULT_DROPDOWN, value: DEFAULT_DROPDOWN },
              ...data.assets.map(asset => ({ title: asset, value: asset })),
            ]}
            value={asset}
            onChange={e => setAsset(e.target.value)}
          />
          <InputBlock
            style={{ marginLeft: 10, marginTop: 'auto', width: 150, display: 'flex' }}
            label="Balance >="
            type="number"
            value={balanceFilter}
            onChange={e => {
              setBalanceFilter(e.target.value);
            }}
          />
          <InputBlock
            style={{ marginLeft: 10, marginTop: 'auto', width: 150, display: 'flex' }}
            label="Amount >="
            type="number"
            value={usedAmtFilter}
            onChange={e => {
              setUsedAmtFilter(e.target.value);
            }}
          />
        </div>
        <div style={{ marginTop: 15, marginBottom: 10 }}>
          <Button
            key={DAY_MS}
            blue={duration !== DAY_MS}
            style={{ marginRight: 10 }}
            onClick={() => setDuration(DAY_MS)}
          >
            Last 24H
          </Button>
          <Button
            key={WEEK_MS}
            blue={duration !== WEEK_MS}
            style={{ marginRight: 10 }}
            onClick={() => setDuration(WEEK_MS)}
          >
            Last Week
          </Button>
          <Button
            key={MONTH_MS}
            blue={duration !== MONTH_MS}
            style={{ marginRight: 10 }}
            onClick={() => setDuration(MONTH_MS)}
          >
            Last Month
          </Button>
        </div>

        <Table>
          <tbody>
            <Tr>
              <TH>Exchange</TH>
              <TH>Asset</TH>
              <TH
                right
                style={{ cursor: 'pointer' }}
                onClick={() => sortClick(PARAM_BALANCE)}
                arrow={balanceArrow}
              >
                Current Balance
              </TH>
              <TH
                right
                style={{ cursor: 'pointer' }}
                onClick={() => sortClick(PARAM_USED_AMT)}
                arrow={usedAmtArrow}
              >
                Used Amount
              </TH>
            </Tr>
            {sortedData.map(data => (
              <BalanceUsageRow key={`${data.exchange}-${data.asset}`} data={data} />
            ))}
          </tbody>
        </Table>
      </div>
    </MainCardContainer>
  );
};

export default BalanceUsage;

const getArrowValue = (tickerSortOrder?: 'ASC' | 'DESC') =>
  tickerSortOrder === 'ASC' ? 'DOWN' : tickerSortOrder === 'DESC' ? 'UP' : undefined;

const getCompare = (prop: string, reverse?: boolean) => {
  return (a: any, b: any) => (a[prop] - b[prop]) * (reverse ? -1 : 1);
};
