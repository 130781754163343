import { zeroPadded } from './formatUtils';

export const dateToInput = (d: Date) =>
  `${d.getFullYear()}-${zeroPadded(d.getMonth() + 1)}-${zeroPadded(d.getDate())}T${zeroPadded(
    d.getHours(),
  )}:${zeroPadded(d.getMinutes())}:${zeroPadded(d.getSeconds())}`;

export const dateMinusMin = (mins: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - mins);
  return date;
};
