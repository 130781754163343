import * as React from 'react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { RouterPaths } from '../routes/RouterPaths';
import CentredLoader from './miscellaneous/CenteredLoader';

interface IProps {
  redirectRoute?: string;
}

const Logout: FC<IProps> = ({ redirectRoute }) => {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  // const dispatch = useDispatch();
  // const [logoutMut] = useMutation<logout>(LOGOUT, {
  //   client,
  // });

  useEffect(() => {
    const onLogout = () => {
      setToken('');
      navigate(redirectRoute || RouterPaths.ROOT, { replace: true });
    };
    // logoutMut()
    //   .then(onLogout)
    //   .catch(onLogout);
    onLogout();
  }, [navigate, setToken, redirectRoute]);

  return <CentredLoader />;
};

export default Logout;
