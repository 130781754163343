import { AxiosError } from 'axios';
import useAxios from 'axios-hooks';
import * as React from 'react';
import { createContext, FC, useContext, useEffect } from 'react';
import { Asset } from '../types/axios/Assets';
import { Ticker } from '../types/axios/Tickers';

interface IAssets {
  assets?: Asset[];
  tickers?: Ticker[];
  loading: boolean;
  errors?: AxiosError<any>[];
}
const GlobalStateCtx = createContext<IAssets>({ loading: false });

const RETRY_DELAY = 60 * 1000;

const GlobalStateProvider: FC = ({ children }) => {
  const [assetObj, fetchAssets] = useAxios<Asset[]>({
    url: '/asset/getAll',
    method: 'GET',
  });

  const [tickerObj, fetchTickers] = useAxios<Ticker[]>({
    url: '/ticker/getAll',
    method: 'GET',
  });

  useEffect(() => {
    let timeout: number | null = null;
    timeout = window.setTimeout(() => fetchAssets(), RETRY_DELAY);
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [assetObj.error, fetchAssets]);

  useEffect(() => {
    let timeout: number | null = null;
    timeout = window.setTimeout(() => fetchTickers(), RETRY_DELAY);

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [tickerObj.error, fetchTickers]);

  const errors: AxiosError<any>[] = [];
  if (assetObj.error) {
    errors.push(assetObj.error);
  }
  if (tickerObj.error) {
    errors.push(tickerObj.error);
  }

  return (
    <GlobalStateCtx.Provider
      value={{
        assets: assetObj.data,
        tickers: tickerObj.data,
        loading: assetObj.loading || tickerObj.loading,
        errors: !!errors.length ? errors : undefined,
      }}
    >
      {children}
    </GlobalStateCtx.Provider>
  );
};

export default GlobalStateProvider;

export const useGlobalState = () => useContext(GlobalStateCtx);
