import useAxios from 'axios-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../routes/RouterPaths';
import { Button } from '../../styled/buttons/Button';
import { clrError } from '../../styled/colors';
import { H3 } from '../../styled/miscellaneous/hx';
import { Table, Th, Tr } from '../../styled/table/table';
import { IMarketTakerTicker, MarketTakerTicker } from '../../types/axios/MarketTaker';
import { ITicker } from '../../types/axios/PlaceOrder';
import { AUTH_ERROR, ILLEGAL_OPERATION_ERROR, isAxiosError } from '../../utils/axiosUtils';
import CentredLoader from '../miscellaneous/CenteredLoader';
import ErrorBox from '../miscellaneous/ErrorBox';
import { StatusBox } from '../miscellaneous/status-box';
import { useAuth } from '../../hooks/useAuth';
import MarketTakerEditTickersRow from './MarketTakerEditTickersRow';

interface IProps {
  mtId: string;
  tickers: ITicker[];
}

const MarketTakerEditTickers: FC<IProps> = ({ mtId, tickers }) => {
  const [postError, setPostError] = useState('');
  const navigate = useNavigate();
  const { token } = useAuth();
  const [{ data, error }, fetch] = useAxios<MarketTakerTicker[]>(
    {
      url: `/market-taker-ticker/getAllByMt/${mtId}`,
      method: 'GET',
      headers: { authorization: token },
    },
    { useCache: false },
  );

  const [, createTicker] = useAxios<MarketTakerTicker>(
    {
      url: '/market-taker-ticker/create',
      method: 'POST',
      headers: { authorization: token },
    },
    { manual: true },
  );

  const fetchMT = async () => {
    await fetch();
  };

  const onCreate = async () => {
    setPostError('');
    try {
      await createTicker({
        data: {
          mtId: Number(mtId),
          minAmt: 10,
          maxAmt: 1000,
          probability: 10,
          ticker: '',
          pricePrecision: 2,
          quantityPrecision: 5,
        } as IMarketTakerTicker,
      });
      await fetchMT();
    } catch (error) {
      if (isAxiosError(error, AUTH_ERROR)) {
        navigate(RouterPaths.LOG_OUT);
      } else if (isAxiosError(error, ILLEGAL_OPERATION_ERROR)) {
        setPostError('Cannot update Market Taker while it is enabled. Please disable it first.');
      } else {
        setPostError('Server error.');
      }
    }
  };

  useEffect(() => {
    if (error && isAxiosError(error, AUTH_ERROR)) {
      navigate(RouterPaths.LOG_OUT);
    }
  }, [error, navigate]);

  if (error) {
    return <ErrorBox />;
  }
  if (!data) {
    return <CentredLoader />;
  }

  return (
    <div style={{ marginTop: 15 }}>
      <H3>Ticker probabilities</H3>
      <Table>
        <tbody>
          <Tr>
            <Th>Ticker</Th>
            <Th>Prob (%)</Th>
            <Th>Min amt</Th>
            <Th>Max amt</Th>
            <Th>Price prec</Th>
            <Th>Qty prec</Th>
            <Th colSpan={2}>Actions</Th>
          </Tr>
          {data.map(data => (
            <MarketTakerEditTickersRow
              key={data.id}
              data={data}
              tickers={tickers}
              refetch={fetchMT}
              setError={setPostError}
            />
          ))}
        </tbody>
      </Table>
      <Button
        blue
        style={{
          marginTop: 15,
          padding: 8,
          width: '100%',
        }}
        onClick={onCreate}
      >
        Add New
      </Button>
      {postError && <StatusBox style={{ color: clrError }} text={postError} />}
    </div>
  );
};

export default MarketTakerEditTickers;
